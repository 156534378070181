import React from "react";

import emoji from "../../../../images/emoji.svg";

import styles from "./IndexUpEmoji.module.scss";

interface Props {
  isSmall?: boolean;
}

const IndexUpEmoji: React.FC<Props> = ({ isSmall }) => {
  return (
    <p className={styles.indexUpEmojiWrapper}>
      <img
        className={styles.indexUpEmoji}
        src={emoji}
        alt={"^"}
        style={
          isSmall
            ? {
                marginLeft: 2,
                width: 16,
                top: 0,
              }
            : { marginLeft: 1 }
        }
      />
    </p>
  );
};

export default IndexUpEmoji;
