import { useCallback, useEffect, useMemo, useState } from "react";

import { map } from "lodash";

const mobileContentWidth = 720;
const pixelPerWidth = 1 / mobileContentWidth;

export default function useScale(...pxs: Array<number>): Array<number> {
  const [innerWidth, setInnerWidth] = useState(typeof window !== "undefined" ? window.innerWidth : 0);

  const onResize = useCallback(() => {
    setInnerWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  return useMemo(() => {
    if (innerWidth >= 720) {
      return pxs;
    }

    return map(pxs, (px) => px * pixelPerWidth * innerWidth);
  }, [pxs, innerWidth]);
}
