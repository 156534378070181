/** @jsxImportSource @emotion/react */
import type { FC, PropsWithChildren } from "react";

import { css } from "@emotion/react";
import { ConfirmPassIcon, Typography } from "@PRNDcompany/heydealer-ui";

type ChecklistItemProps = PropsWithChildren<unknown>;

const checklistItemWrapperStyle = css`
  display: grid;
  align-items: center;
  gap: 0.25rem;

  grid-template-columns: 1rem 1fr;
`;

const ChecklistItem: FC<ChecklistItemProps> = ({ children }) => {
  return (
    <div css={checklistItemWrapperStyle}>
      <ConfirmPassIcon color="brand_2" size={16} />
      <Typography variant="body_2">{children}</Typography>
    </div>
  );
};

export default ChecklistItem;
