import React, { useEffect, useState } from "react";

import classNames from "classnames";

import Typography from "../../../../components/Typography/Typography";

import styles from "./Radiobox.module.scss";

interface Props {
  // style
  label?: string;
  variant?: "list" | "box";
  size?: "big" | "small";
  warning?: boolean;

  // customize
  className?: string;
}

const Radiobox: React.FC<Props & Omit<React.InputHTMLAttributes<any>, "size">> = ({
  label = "",
  variant = "list",
  warning = false,
  size = "small",

  // customize
  className = "",
  ...otherProps
}) => {
  const [id, setId] = useState<string>("");

  useEffect(() => {
    setId(`radioInput${Math.random()}`);
  }, []);

  return (
    <label
      htmlFor={id}
      className={classNames(
        styles.wrapper,
        styles[size],
        styles[variant],
        otherProps.checked && styles.checked,
        warning && styles.warning,
        otherProps.disabled && styles.disabled,
        className
      )}
    >
      <input type={"radio"} id={id} {...otherProps} style={{ display: "none" }} onChange={otherProps.onChange} />
      <span className={classNames(styles.radio)}>{otherProps.checked && <span className={styles.checkIcon} />}</span>
      <Typography
        className={classNames(styles.label, otherProps.disabled ? styles.disabledTypo : "")}
        variant={size === "big" ? "Body_16" : "Body_14"}
      >
        {label}
      </Typography>
    </label>
  );
};

export default Radiobox;
