import React from "react";

import { ResizeObserver } from "@juggle/resize-observer";
import classNames from "classnames";
import { get } from "lodash";
import useMeasure from "react-use-measure";

import LightInk from "../../../../components/LightInk/LightInk";
import Typography from "../../../../components/Typography/Typography";
import icArrowDown from "../../../../images/ic_arrow_down_gray.svg";
import picQBlue from "../../../../images/pic_q_blue.png";

import styles from "./FAQCommonStyle.module.scss";

interface Props {
  isOpened: boolean;
  toggleFAQ: () => void;
  title: string;
  content: string;
}

const FAQ: React.FC<Props> = ({ isOpened, toggleFAQ, title, content }) => {
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver });
  const height = get(bounds, "height");

  const contentWrapperInlineStyle = {
    ...(isOpened ? { height, opacity: 1 } : { height, opacity: 0 }),
    transitionDuration: "0.2s",
  };

  return (
    <div>
      <header className={styles.FAQHeader} onClick={toggleFAQ}>
        <img className={styles.q} src={picQBlue} alt={"Q"} />
        <p className={styles.FAQTitle}>{title}</p>
        <span className={styles.vWrapper}>
          <img className={classNames(styles.v, isOpened && styles.opened)} src={icArrowDown} alt={"V"} />
        </span>
        <LightInk />
      </header>
      <section className={styles.FAQContent} style={contentWrapperInlineStyle}>
        <div ref={ref}>
          {isOpened && (
            <Typography variant={"bodyMulti"} className={styles.content}>
              {content}
            </Typography>
          )}
        </div>
      </section>
    </div>
  );
};

export default FAQ;
