/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";

import { colors } from "@PRNDcompany/heydealer-ui";
import { captureMessage } from "@sentry/react";
import Cookies from "js-cookie";
import { useParams } from "react-router";

import { setAuthorizedRequesterToken } from "../../apis/pxios";
import Footer from "../../components/Footer/Footer";
import Attachment from "../../components/MortgageAndAttachment/Attachment/Attachment";
import Border from "../../components/MortgageAndAttachment/Border/Border";
import Mortgage from "../../components/MortgageAndAttachment/Mortgage/Mortgage";
import Page from "../../components/Page";
import Typography from "../../components/Typography/Typography";

const MortgageAndAttachmentPage: React.FC = () => {
  const [isTokenSet, setTokenSet] = useState<boolean>(false);
  const { hashId } = useParams<{ hashId: string }>();

  useEffect(() => {
    document.body.style.background = `${colors.white_3}`;
    captureMessage("Deprecated page access: MortgageAndAttachmentPage");
  }, []);

  useEffect(() => {
    const token = Cookies.get("csrftoken");
    if (token) {
      setAuthorizedRequesterToken(token);
    }

    setTokenSet(true);
  }, [hashId]);

  if (!isTokenSet) {
    return null;
  }

  return (
    <Page title="압류 저당" background="base_white">
      <section css={{ padding: "1.25rem 1.5rem", display: "grid", gap: "0.5rem" }}>
        <Typography variant="Headline_24">압류·저당 안내</Typography>
        <Typography variant="Body_16">
          <ul>
            <li>직접 기관에 전화해서 해지해주세요.</li>
            <li>압류・저당 금액과 별도로 해지에 필요한 해지비용도 납부해주세요.</li>
            <li>하나라도 남아있다면 명의 이전이 불가능해요.</li>
          </ul>
        </Typography>
      </section>
      <Border height={8} />
      <Attachment hashId={hashId} />
      <Border height={1} />
      <Mortgage hashId={hashId} />
      <Footer />
    </Page>
  );
};

export default MortgageAndAttachmentPage;
