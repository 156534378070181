import React from "react";

import AppBar from "../../components/AppBar/AppBar";
import Faq from "../../components/FAQ/FAQ";
import Footer from "../../components/Footer/Footer";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import UIrscTagNew from "../../images/u_irsc_tag_new.svg";

import TradeProcess from "./TradeProcess/TradeProcess";

import styles from "./ContactFreePage.module.scss";

import "intersection-observer";

const ContactFreePage: React.FC = () => {
  return (
    <PageWrapper title="비대면 간편거래란?">
      <div className={styles.container}>
        <AppBar isFixed />
        <div className={styles.body}>
          <div className={styles.header}>
            <h1>비대면 간편거래란?</h1>
            <img className={styles.newTag} src={UIrscTagNew} alt="new" />
          </div>
          <div className={styles.section1}>
            <div className={styles.paragraph}>
              <div className={styles.subTitle}>· 딜러 대면없이 편하게 거래하세요.</div>
              <div className={styles.content}>진단·이전·송금까지 헤이딜러 매니저가 모두 직접 진행합니다.</div>
            </div>
            <div className={styles.paragraph}>
              <div className={styles.subTitle}>· 헤이딜러 진단센터에서 투명하게 진단합니다.</div>
              <div className={styles.content}>
                무료 픽업으로 헤이딜러 진단센터에 입고되며, 1시간 내 가격이 확정됩니다.
              </div>
              <div className={styles.list}>
                · 주요상태 같음 : 처음견적 그대로 확정
                <br />· 주요상태 다름 : 진단결과 및 최종가격 재안내
              </div>
              <div className={styles.description}>* 재경매 또는 돌려받기 가능 (무료)</div>
            </div>
          </div>
          <TradeProcess />
        </div>
        <Faq />
        <Footer />
      </div>
    </PageWrapper>
  );
};

export default ContactFreePage;
