import { css } from "@emotion/react";
import { colors } from "@PRNDcompany/heydealer-ui";

export const wrapperStyle = css`
  display: grid;
  gap: 16px;

  box-sizing: border-box;

  margin: 16px 24px 0;
`;

export const satisfactionListStyle = css`
  display: grid;
  gap: 8px;

  grid-template-columns: repeat(3, 1fr);
`;

export const satisfactionStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  height: 88px;

  padding: 0;

  border: 1px solid ${colors.white_4};
  border-radius: 4px;

  transition: background-color 0.2s;

  align-self: stretch;
`;

export const satisfactionSelectedStyle = css`
  background-color: ${colors.brand_2_alpha_8};
  border-color: ${colors.brand_2};
`;

export const satisfactionIconStyle = css`
  width: 36px;
  height: 36px;
`;
