import { toast } from "@PRNDcompany/heydealer-ui";
import type { AxiosInstance, AxiosInterceptorManager, AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";
import Cookies from "js-cookie";

import { defaultToken } from "../cores/defaultToken";

interface PxiosResponseData<T = any> extends AxiosResponse<T> {
  toast?: { message: string; type: "default" | "success" | "error" };
}

interface PxiosInstance extends AxiosInstance {
  interceptors: {
    request: AxiosInterceptorManager<AxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse<PxiosResponseData>>;
  };
}

const appOs = Cookies.get("App-Os") || "";
const appType = Cookies.get("App-Type") || "";
const appVersion = Cookies.get("App-Version") || "";

const testBaseUrl = Cookies.get("Base-Url");
const isProduction = process.env.REACT_APP_ENV === "production";

const pxios: PxiosInstance = axios.create({
  baseURL: isProduction || !testBaseUrl ? process.env.REACT_APP_API : `${new URL(testBaseUrl).origin}/`,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  headers: { "App-Os": appOs, "App-Type": appType, "App-Version": appVersion },
});

pxios.interceptors.response.use(
  (response) => {
    if (response.data.toast) {
      const { message, type } = response.data.toast;
      toast.create(message, type === "error" ? "default" : type);
    }

    return response;
  },
  (err) => {
    if (err.response?.data?.toast) {
      const { message, type } = err.response.data.toast;
      toast.create(message, type === "error" ? "default" : type);
    }

    throw new Error(err.response.data.toast.message);
  }
);

if (defaultToken) {
  pxios.defaults.headers.common["Authorization"] = `JWT ${defaultToken}`;
}

export const setAuthorizedRequesterToken = (token: string) => {
  pxios.defaults.headers["Authorization"] = `JWT ${token}`;
};

export default pxios;
