import taxios from "@PRNDcompany/taxios";

import { BASE_PATH } from "../BASE_PATH";

type GetRecentMechanicInspectionReportsParams = {
  model: string;
};

export type MechanicInspectionReport = {
  image_url: string;
  grade: "excellent" | "good" | /* "average" | */ "poor";
  inspection_date: string;
  report_url: string;
};
type GetRecentMechanicInspectionReportsResponse = {
  title: string;
  mechanic_inspection_reports: MechanicInspectionReport[];
};

export const getRecentMechanicInspectionReportsAPI = async (params: GetRecentMechanicInspectionReportsParams) => {
  return (
    await taxios.get<GetRecentMechanicInspectionReportsResponse>(`${BASE_PATH}/recent_mechanic_inspection_reports/`, {
      params,
    })
  ).data;
};
