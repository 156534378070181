import { isCustomerAppWebview } from "@PRNDcompany/heydealer-lib";
import { toast } from "@PRNDcompany/heydealer-ui";
import taxios, { initTAxiosResponseInterceptor } from "@PRNDcompany/taxios";
import Cookies from "js-cookie";

const createToast = (message: string, type: "default" | "success" | "error") =>
  toast.create(message, type === "error" ? "default" : type);

const requestInterceptors: number[] = [];
const responseInterceptors: number[] = [];

export const getDefaultBaseURL = () => {
  const testBaseURL = Cookies.get("Base-Url") ?? process.env.REACT_APP_API ?? "";

  return process.env.REACT_APP_ENV === "production" || !testBaseURL
    ? process.env.REACT_APP_API
    : `${new URL(testBaseURL).origin}/`;
};

export const setBaseURL = (baseURL: string) => {
  taxios.defaults.baseURL = baseURL;
};

export const init = () => {
  requestInterceptors.map((interceptorId) => taxios.interceptors.request.eject(interceptorId));
  responseInterceptors.map((interceptorId) => taxios.interceptors.response.eject(interceptorId));

  requestInterceptors.length = 0;
  responseInterceptors.length = 0;

  taxios.defaults.xsrfCookieName = "csrftoken";
  taxios.defaults.xsrfHeaderName = "X-CSRFToken";
  taxios.defaults.withCredentials = true;

  setBaseURL(getDefaultBaseURL());

  requestInterceptors.push(
    taxios.interceptors.request.use((request) => {
      if (!request.headers) {
        throw new Error("Axios Error");
      }

      const carToken = sessionStorage.getItem("CAR-TOKEN");
      if (carToken) {
        request.headers.Authorization = `CAR-TOKEN ${carToken}`;
      }

      if (isCustomerAppWebview) {
        if (!request.headers.Authorization) {
          const csrfToken = Cookies.get("csrftoken");

          request.headers.Authorization = csrfToken ? `JWT ${csrfToken}` : "";
        }
      }

      request.headers["App-Os"] = Cookies.get("App-Os") || "";
      request.headers["App-Version"] = Cookies.get("App-Version") || "";
      request.headers["App-Type"] = Cookies.get("App-Type") || "";

      return request;
    })
  );

  responseInterceptors.push(initTAxiosResponseInterceptor(createToast));
};
