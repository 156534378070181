/** @jsxImportSource @emotion/react */

import type { FC, PropsWithChildren } from "react";

import { css } from "@emotion/react";

type AspectRatioProps = PropsWithChildren<{
  heightPerWidth: number;
}>;

const ratioKeeperStyle = css`
  position: relative;

  box-sizing: content-box;
  width: 100%;

  overflow: hidden;
`;

const wrapperStyle = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const AspectRatio: FC<AspectRatioProps> = ({ heightPerWidth, children }) => (
  <div css={[ratioKeeperStyle, { paddingBottom: `${heightPerWidth * 100}%` }]}>
    <div css={wrapperStyle}>{children}</div>
  </div>
);

export default AspectRatio;
