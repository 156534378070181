/** @jsxImportSource @emotion/react */
import type { ComponentProps, FC } from "react";
import { useLayoutEffect, useState } from "react";

import type { Interpolation, Theme } from "@emotion/react";
import { css } from "@emotion/react";
import { PlaceholderThumbnail, Skeleton } from "@PRNDcompany/heydealer-ui";

type ThumbnailProps = ComponentProps<"img"> & { wrapperCSS?: Interpolation<Theme> };

const imgDefaultStyle = css`
  width: 100%;
  height: 100%;

  object-fit: cover;
`;

const Thumbnail: FC<ThumbnailProps> = ({ src, wrapperCSS, ...props }) => {
  const [state, setState] = useState<"loading" | "loaded" | "error">("loading");

  useLayoutEffect(() => {
    if (!src) {
      setState("error");

      return;
    }

    const image = new Image();
    image.src = src;
    image.onload = () => setState("loaded");
    image.onerror = () => setState("error");
  }, [src]);

  return (
    <div css={wrapperCSS}>
      {state === "loaded" ? (
        <img src={src} css={imgDefaultStyle} {...props} />
      ) : state === "loading" ? (
        <Skeleton css={{ width: "100%", height: "100%" }} />
      ) : (
        <PlaceholderThumbnail type="picture" />
      )}
    </div>
  );
};

export default Thumbnail;
