import React from "react";

import classNames from "classnames";

import styles from "./Video.module.scss";

interface Props {
  src: string;
  className?: string;
}

const Video: React.FC<Props> = ({ src, className }) => {
  return (
    <video
      autoPlay
      muted
      loop
      playsInline
      data-object-fit="cover"
      className={classNames(styles.video, className)}
      poster="noposter.png"
    >
      <source src={src} type="video/mp4" />
    </video>
  );
};

export default Video;
