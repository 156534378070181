import type { HTMLProps } from "react";
import React from "react";

import classNames from "classnames";

import styles from "./Box.module.scss";

interface Props {
  variant?: "blue" | "red" | "yellow" | "gray" | "white";
}

const Box: React.FC<Props & HTMLProps<HTMLDivElement>> = ({ variant = "white", children, ...otherProps }) => {
  const props = Object.assign(otherProps, { className: classNames(styles.box, styles[variant], otherProps.className) });

  return <div {...props}>{children}</div>;
};

export default Box;
