import React from "react";

import classNames from "classnames";
import { get } from "lodash";

import BlueUpLeftArrow from "../../../images/emojis/blue-up-left-arrow.png";
import FaceWithPleadingEyes from "../../../images/emojis/face-with-pleading-eyes.png";
import grinningFace from "../../../images/emojis/grinning-face.png";
import SmilingFaceWithSunglasses from "../../../images/emojis/smiling-face-with-sunglasses.png";
import wavingHand from "../../../images/emojis/waving-hand.png";
import whiteUpPointingIndex from "../../../images/emojis/white-up-pointing-index.png";

import styles from "./Emoji.module.scss";

const variants = {
  "face-with-pleading-eyes": FaceWithPleadingEyes,
  "smiling-face-with-sunglasses": SmilingFaceWithSunglasses,
  "blue-up-left-arrow": BlueUpLeftArrow,
  "white-up-pointing-index": whiteUpPointingIndex,
  "grinning-face": grinningFace,
  "waving-hand": wavingHand,
};

export type EmojiVariants =
  | "face-with-pleading-eyes"
  | "smiling-face-with-sunglasses"
  | "blue-up-left-arrow"
  | "white-up-pointing-index"
  | "grinning-face"
  | "waving-hand";

interface Props {
  size?: number;
  name: EmojiVariants;
  className?: string;
}

const Emoji: React.FC<Props> = ({ size, name, className }) => {
  return (
    <span
      className={classNames(styles.emoji, className)}
      style={{ width: size, height: size, background: `url(${get(variants, name)}) no-repeat center` }}
    />
  );
};

export default Emoji;
