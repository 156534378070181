/** @jsxImportSource @emotion/react */
import type { PropsWithChildren } from "react";
import React from "react";

import { colors } from "@PRNDcompany/heydealer-ui";

import Layout from "../../components/Layout/Layout";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import Typography from "../../components/Typography/Typography";
import { ReactComponent as ConfirmPass } from "../../images/teslaCustomerBenefitPopup/confirm_pass.svg";

import styles from "./TeslaCustomerBenefitPopup.module.scss";

const TeslaCustomerBenefitPopup: React.FC<PropsWithChildren> = () => {
  return (
    <PageWrapper title="테슬라 구매고객 혜택">
      <Layout>
        <div className={styles.content}>
          <Typography variant={"Headline_24"} className={styles.title}>
            테슬라 프로모션
          </Typography>
          <div>
            <Typography variant="Subtitle_16" className={styles.subTitle}>
              프로모션 대상(2가지 모두 필요)
            </Typography>
            <ul className={styles.list}>
              <li className={styles.listItem}>
                <ConfirmPass className={styles.confirmPass} />
                <Typography variant="Body_16">헤이딜러에 차량 판매 완료하신 고객</Typography>
              </li>
              <li className={styles.listItem}>
                <ConfirmPass className={styles.confirmPass} />
                <Typography variant="Body_16">
                  같은 명의로 테슬라(Model S, 3, X, Y) 차량을 인도 완료하신 고객
                </Typography>
              </li>
            </ul>
          </div>
          <div css={{ display: "grid", gap: "0.5rem" }}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>
                    <Typography variant="Subtitle_16" className={styles.thText}>
                      판매차량 금액
                    </Typography>
                  </th>
                  <th>
                    <Typography variant="Subtitle_16" className={styles.thText}>
                      거래완료 시 혜택
                    </Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className={styles.tbodyTr}>
                  <td>
                    <Typography variant="Body_16">3,001만원 이상</Typography>
                  </td>
                  <td>
                    <Typography variant="Body_16">
                      <b>10만원 상품권</b>
                    </Typography>
                  </td>
                </tr>
                <tr className={styles.tbodyTr}>
                  <td>
                    <Typography variant="Body_16">3,000만원 이하</Typography>
                  </td>
                  <td>
                    <Typography variant="Body_16">
                      <b>5만원 상품권</b>
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
            <Typography variant="Body_14">
              <div css={{ display: "grid", gap: "0.25rem", gridTemplateColumns: "1rem 1fr", color: colors.white_7 }}>
                <div>💸</div>
                <div>
                  상품권은 차량 인도일에 맞춰 헤이딜러에 등록된 <br />
                  연락처로 발송됩니다.
                </div>
              </div>
            </Typography>
            <Typography variant="Body_14">
              <div css={{ display: "grid", gap: "0.25rem", gridTemplateColumns: "1rem 1fr", color: colors.white_7 }}>
                <div>📢</div>
                <div>테슬라 프로모션은 2023년 12월 31일에 종료됩니다.</div>
              </div>
            </Typography>
          </div>
        </div>
      </Layout>
    </PageWrapper>
  );
};

export default TeslaCustomerBenefitPopup;
