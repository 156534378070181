import React, { useState } from "react";

import classNames from "classnames";

import emoji from "../../../images/emoji.svg";
import icHelp from "../../../images/ic_help_gray.svg";

import styles from "./InfoModal.module.scss";

interface Props {
  type: "work" | "notWork";
}

const InfoModal: React.FC<Props> = ({ type }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className={styles.infoWrapper} onBlur={() => setOpen(false)} tabIndex={1}>
      <img className={styles.infoIcon} src={icHelp} alt="도움말" onClick={() => setOpen((prev) => !prev)} />
      <div className={classNames(styles.infoModalWrapper, styles[isOpen ? "show" : "hide"])}>
        {isOpen ? (
          type === "work" ? (
            <p>개인사업자가 사업상의 경비(차량유지비/감가상각비)로 사용한 차량을 의미합니다.</p>
          ) : (
            <>
              <p style={{ marginBottom: 20 }}>
                개인사업자가 사업상의 경비(차량유지비/감가상각비)로 사용하지 않은 차량을 의미합니다.
              </p>
              <p style={{ paddingInlineStart: 20 }}>
                <img src={emoji} alt={"|"} /> 일반적으로 전년도 종합소득세 신고 기준,
              </p>
              <p> 장부에 고정자산으로 등재하거나 비용으로 반영한 사실이 단 한번도 없어야 합니다.</p>
            </>
          )
        ) : null}
      </div>
    </div>
  );
};

export default InfoModal;
