import "./styles/index.scss";
import React from "react";

import { assert, initSentry, isAppWebview } from "@PRNDcompany/heydealer-lib";
import { GlobalStyle } from "@PRNDcompany/heydealer-ui";
import { QueryClientProvider } from "@tanstack/react-query";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { queryClient } from "./modules/react-query";
import { init as initTAxios } from "./modules/taxios";

if (process.env.REACT_APP_ENV !== "local") {
  initSentry({ dsn: process.env.REACT_APP_SENTRY_DSN, environment: process.env.REACT_APP_ENV });
}

initTAxios();

const rootElement = document.getElementById("root");
assert(!!rootElement, "root element not found");

createRoot(rootElement).render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <GlobalStyle mobile isAppWebview={isAppWebview} />
      <App />
    </BrowserRouter>
  </QueryClientProvider>
);
