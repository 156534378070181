/** @jsxImportSource @emotion/react */

import type { FC } from "react";

import { Global } from "@emotion/react";
import { close } from "@PRNDcompany/heydealer-lib";
import {
  DeprecatedAppBar as AppBar,
  colors,
  Divider,
  StrokeCloseIcon,
  TitleBar,
  Typography,
  typographyStyles,
} from "@PRNDcompany/heydealer-ui";

import PageWrapper from "../../components/PageWrapper";
import { globalPageStyle } from "../../styles";

import { ReactComponent as PictoAlert } from "./assets/img/picto_alert.svg";
import { ReactComponent as PictoAllDocuImg } from "./assets/img/picto_alldocu.svg";
import { ReactComponent as PictoDocuImg } from "./assets/img/picto_docu.svg";
import { ReactComponent as PictoTaxImg } from "./assets/img/picto_tax.svg";
import InfoItem from "./components/InfoItem";
import { contentAreaStyle, listStyle, olStyle } from "./style";

const CarInsuranceRefundPage: FC = () => (
  <PageWrapper title="자동차보험료 돌려받기">
    <Global styles={globalPageStyle} />
    <AppBar leftIcon={{ icon: StrokeCloseIcon, onClick: close }} sticky />
    <TitleBar
      title="자동차보험료 돌려받기"
      description={"보험기간을 다 채우지 않고 판매했다면,\n남은 일수 만큼 보험료를 돌려받을 수 있어요."}
    />
    <Divider />
    <div css={contentAreaStyle}>
      <InfoItem Icon={PictoTaxImg}>
        <div>
          <Typography variant="subtitle_2">환급 금액</Typography>
          <Typography variant="body_1">사용 일수를 제외한 나머지 기간 일할 계산</Typography>
        </div>
      </InfoItem>
      <InfoItem Icon={PictoDocuImg}>
        <div>
          <Typography variant="subtitle_2">준비물</Typography>
          <Typography variant="body_1">
            이전이 완료된 자동차등록증 사진 또는 <span css={{ display: "inline flow-root" }}>자동차등록원부 사진</span>
          </Typography>
        </div>
        <div css={{ color: colors.white_7 }}>
          <Typography variant="subtitle_4">만약 내 차가 수출·폐차로 인해 말소가 된다면?</Typography>
          <Typography variant="caption_1">
            자동차등록증 대신 자동차말소등록사실증명서, 폐차인수증명서, 자동차 등록원부(갑) 중 택 1
          </Typography>
        </div>
      </InfoItem>
      <InfoItem Icon={PictoAllDocuImg}>
        <div>
          <Typography variant="subtitle_2">환급 방법</Typography>
          <ol css={[listStyle, olStyle, typographyStyles.body_1]}>
            <li>명의이전 완료</li>
            <li>
              보험사 고객센터에 전화하여{" "}
              <span css={{ display: "inline flow-root" }}>&apos;자동차 판매로 인한 보험 해지&apos; 요청</span>
            </li>
            <li>
              담당자 안내에 따라 준비한 <span css={{ display: "inline flow-root" }}>이전 증명 서류 사본 제출</span>
            </li>
            <li>계좌번호로 환급금 입금</li>
          </ol>
        </div>
      </InfoItem>
      <InfoItem Icon={PictoAlert}>
        <div css={{ color: colors.critical_3 }}>
          <Typography variant="subtitle_2">유의해주세요.</Typography>
          <Typography variant="body_1">
            명의 이전 전에 미리 보험을 해지하시면{" "}
            <span css={{ display: "inline flow-root" }}>의무보험 미가입으로 과태료가 발생돼요.</span>
          </Typography>
        </div>
        <ul css={typographyStyles.body_2}>
          <li>명의가 이전되기 전까지는 자동차 보험을 꼭 유지해주세요. </li>
          <li>수출·폐차로 인해 말소가 된다면, 말소증명서가 발급되기 전까지 보험을 유지해주세요.</li>
          <li>
            보험 만기 예정이시거나, 신차 구매로 보험 승계가 필요하시다면, 판매한 차량은 단기 책임보험을 가입해주세요.
          </li>
        </ul>
      </InfoItem>
    </div>
  </PageWrapper>
);

export default CarInsuranceRefundPage;
