export const faqTypesArray = ["general", "zero", "self"] as const;

export const faqGroupsArray = [
  "general",
  "zero",
  "zero_pre_inspect",
  "zero_auction",
  "zero_consignment",
  "zero_trade",
  "self",
  "self_auction",
  "self_dealer",
  "self_trade",
] as const;

export const faqTypeChipLabelMap: Record<typeof faqTypesArray[number], string> = {
  general: "일반",
  zero: "zero 경매",
  self: "self 경매",
};

export const faqGroupTextMap: Record<typeof faqGroupsArray[number], string> = {
  general: "",
  zero: "",
  zero_pre_inspect: "평가관련",
  zero_auction: "경매관련",
  zero_trade: "거래관련",
  zero_consignment: "탁송관련",
  self: "",
  self_auction: "경매관련",
  self_dealer: "딜러관련",
  self_trade: "거래관련",
};
