import React from "react";

import Typography from "../../../components/Typography/Typography";
import IndexUpEmoji from "../components/IndexUpEmoji";

import styles from "./HeadSection.module.scss";

interface Props {}

const HeadSection: React.FC<Props> = () => {
  return (
    <div className={styles.headSectionWrapper}>
      <Typography className={styles.headTitle} variant={"headline"}>
        진단완료 차량 거래과정
      </Typography>
      <Typography variant={"bodyMulti"}>
        <p>낙찰가 그대로, 사무실에서 편하게 탁송으로 인수받으세요.</p>
        <p>모든 과정과 고객 커뮤니케이션은 헤이딜러가 담당합니다.</p>
      </Typography>
      <section className={styles.blueSectionWrapper}>
        <Typography variant={"caption"}>
          <p>탁송 2시간 전까지 [차대금]을</p>
          <p>헤이딜러 에스크로 계좌로 입금해주세요.</p>
          <p style={{ display: "flex" }}>
            <IndexUpEmoji isSmall /> 탁송출발 직전, 차 대금이 차주 계좌로 안전하게 송금됩니다.
          </p>
          <p style={{ display: "flex" }}>
            <IndexUpEmoji isSmall /> 입금관련 증빙이 제공되며, 송금사고 시 헤이딜러가 책임집니다.
          </p>
          <p style={{ display: "flex" }}>
            <IndexUpEmoji isSmall /> 사업자+비용처리한 차량은 계산서가 정상 발급됩니다.
          </p>
        </Typography>
      </section>
    </div>
  );
};

export default HeadSection;
