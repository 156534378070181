import type { HTMLProps } from "react";
import React from "react";

import classNames from "classnames";

import styles from "./Layer.module.scss";

interface Props {
  variant: "level_0" | "level_2" | "level_8";
}

const Layer: React.FC<Props & HTMLProps<HTMLDivElement>> = ({ children, variant, ...otherProps }) => {
  const props = Object.assign(otherProps, { className: classNames(styles[variant], otherProps.className) });

  return <div {...props}>{children}</div>;
};

export default Layer;
