import React from "react";

import { colors } from "@PRNDcompany/heydealer-ui";
import { ClipLoader } from "react-spinners";

import styles from "./LoadingPage.module.scss";

const LoadingPage: React.FC = () => {
  return (
    <div className={styles.loadingPage}>
      <ClipLoader color={colors.brand_2} />
    </div>
  );
};

export default LoadingPage;
