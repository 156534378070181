/** @jsxImportSource @emotion/react */
import type { ChangeEvent, FC } from "react";
import { useRef } from "react";

import { css } from "@emotion/react";
import {
  Button,
  colors,
  effects,
  EtcDeleteCircleIcon,
  IconButton,
  Typography,
  UploadIcon,
} from "@PRNDcompany/heydealer-ui";
import { useMutation } from "@tanstack/react-query";

import {
  createPostBusinessDocumentSubmitAPI,
  deleteBusinessDocumentFileAPI,
  postBusinessDocumentFilesAPI,
} from "../../apis/cars";
import type { BusinessDocument } from "../../types";

import Thumbnail from "./Thumbnail";

type DocumentListItemProps = {
  document: BusinessDocument;
  refetch: () => Promise<unknown>;
};

const wrapperStyle = css`
  display: grid;
  gap: 1rem;

  padding: 16px;

  border: 1px dashed ${colors.white_4};

  background: ${colors.white_3};

  :not(:first-of-type) {
    border-top: 0;
  }
`;

const previewStyle = css`
  display: grid;
  gap: 1rem;

  box-sizing: border-box;
  width: 100%;

  padding: 0.75rem;

  background: ${colors.white_1};
  ${effects.layer.level_2};

  grid-template-columns: auto 1fr auto;
  word-break: break-all;
`;

const thumbnailStyle = css`
  width: 3.25rem;
  height: 3.25rem;

  border-radius: 0.25rem;

  overflow: hidden;
`;

const DocumentListItem: FC<DocumentListItemProps> = ({ document, refetch }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { mutateAsync: uploadDocumentFile, isLoading: isUploading } = useMutation({
    mutationFn: postBusinessDocumentFilesAPI,
  });

  const { mutateAsync: deleteDocumentFile } = useMutation({ mutationFn: deleteBusinessDocumentFileAPI });
  const { mutateAsync: submitDocument, isLoading: isSubmitting } = useMutation({
    mutationFn: createPostBusinessDocumentSubmitAPI(document.id),
  });

  const handleUploadClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleSubmitClick = async () => {
    await submitDocument();
    await refetch();
  };

  const createHandleClickDeleteButton = (fileId: number) => async () => {
    await deleteDocumentFile(fileId);
    await refetch();
  };

  const handleChangeFileInput = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;
    await Promise.all(
      Array.from(files ?? []).map(async (file) => {
        const formData = new FormData();
        formData.append("business_document", document.id.toString());
        formData.append("file", file);
        await uploadDocumentFile(formData);
      })
    );

    await refetch();
  };

  return (
    <div css={wrapperStyle}>
      <input
        type="file"
        accept="image/x-png,image/gif,image/jpeg,image/heic,image/heif,application/pdf"
        ref={inputRef}
        onChange={handleChangeFileInput}
        multiple
        hidden
      />
      <Typography variant="body_1">{document.description}</Typography>
      {document.files.length > 0 && (
        <div css={{ display: "grid", gap: "0.5rem" }}>
          {document.files.map((file) => (
            <div key={file.id} css={previewStyle}>
              {file.url && <Thumbnail src={file.url} wrapperCSS={thumbnailStyle} />}
              <div css={{ display: "grid", gap: "0.25rem" }}>
                <Typography variant="body_2">{file.name}</Typography>
                <Typography variant="caption_1" color="white_7">
                  {file.size_display}
                </Typography>
              </div>
              <IconButton
                icon={EtcDeleteCircleIcon}
                size={16}
                color="white_5"
                onClick={createHandleClickDeleteButton(file.id)}
                touchAreaSize={48}
                absoluteTouchArea
              />
            </div>
          ))}
        </div>
      )}
      <Button size={48} variant="secondary" icon={UploadIcon} onClick={handleUploadClick} loading={isUploading}>
        파일 업로드
      </Button>
      {document.files.length > 0 && (
        <Button size={48} fullWidth onClick={handleSubmitClick} loading={isSubmitting}>
          자료 제출
        </Button>
      )}
    </div>
  );
};

export default DocumentListItem;
