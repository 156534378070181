/** @jsxImportSource @emotion/react */

import type { VFC } from "react";
import { useEffect } from "react";

import { css, keyframes } from "@emotion/react";
import { isAppWebview, setStatusBar } from "@PRNDcompany/heydealer-lib";
import { colors, Typography } from "@PRNDcompany/heydealer-ui";
import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useInView } from "react-intersection-observer";
import { animated, useSpring } from "react-spring";

import pxios, { setAuthorizedRequesterToken } from "../../apis/pxios";
import AppBar from "../../components/AppBar/AppBar";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { ReactComponent as EtcScrollSVG } from "./assets/etcScroll.svg";

import copyright from "./assets/copyright.png";
import mainImage from "./assets/main.png";
import zero from "./assets/zero2.png";
import Progress from "./Progress";
import ReviewCarousel from "./ReviewCarousel";
import 내차팔기버튼 from "./내차팔기버튼";

const containerStyle = css`
  margin: 0 auto;

  padding-top: calc(48px + env(safe-area-inset-top));
  padding-bottom: 5.5rem;

  max-width: 37.5rem;
`;

const mainSectionStyle = css`
  display: flex;
  flex-direction: column;

  position: relative;

  box-sizing: border-box;
  height: ${(440 * 100) / 360}vw;

  padding: 2.5rem 2rem 0;

  background: url(${mainImage}) center;

  max-height: 46rem;
  background-size: cover;

  > *:not(:last-child) {
    margin-bottom: 1.25rem;
  }
`;

const zeroPriceStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1.5rem;

  border-radius: 0.5rem;

  background: ${colors.white_3};
`;

const customerCountStyle = css`
  color: ${colors.brand_2};
`;

const progressSectionStyle = css`
  padding: 2rem 2rem 2.656rem;

  background: ${colors.white_2};
`;

const progress = [
  {
    title: "평가사가 진단 후 경매등록까지 알아서",
    desc: "차량 진단은 20분이면 충분해요.",
  },
  {
    title: "9,000명 딜러 경쟁 입찰",
    desc: "견적은 48시간 동안 실시간으로 확인 가능해요.",
  },
  {
    title: "감가없이 경매 최고가에 판매결정",
    desc: "3일내에 판매결정 하세요.",
  },
  {
    title: "딜러만남 없이 탁송으로 판매완료",
    desc: "입금업무는 헤이딜러가 책임지고 처리합니다.",
  },
];

const fadeDownAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, 0);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 4px);
  }
`;

const ZeroPage: VFC = () => {
  const token = Cookies.get("csrftoken");
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  useEffect(() => {
    if (isAppWebview) {
      setStatusBar({ color: "#ffffff", content_brightness: "dark" });
    }
  }, []);

  const { data } = useQuery({
    queryKey: [`/v2/customers/zero_retargeting_deeplink/`],
    queryFn: async () => (await pxios.get<{ deeplink_url: string }>(`/v2/customers/zero_retargeting_deeplink/`)).data,
  });
  const countAnimation = useSpring({ count: inView ? 29122 : 0 });

  if (token) {
    setAuthorizedRequesterToken(token);
  }

  return (
    <PageWrapper title="헤이딜러 zero">
      <AppBar isFixed />
      <div css={containerStyle}>
        <section css={mainSectionStyle}>
          <img
            src={copyright}
            alt="새로운 내차팔기 방법, 헤이딜러 zero"
            css={{ width: "100%", objectFit: "contain" }}
          />
          <div>
            <Typography variant="body_1" color="white_1">
              현장감가 zero
            </Typography>
            <Typography variant="body_1" color="white_1">
              딜러만남 zero
            </Typography>
          </div>
          <div
            css={{
              position: "absolute",
              bottom: "1rem",
              left: "50%",
              transform: "translateX(-50%)",
              animation: `${fadeDownAnimation} infinite 1.2s`,
            }}
          >
            <EtcScrollSVG css={{ color: colors.white_1, width: "1.5rem", height: "1.5rem" }} />
          </div>
        </section>
        <section css={{ padding: `2rem 2rem 2.5rem` }}>
          <div css={{ display: "grid", gap: "1rem", marginBottom: "2rem" }}>
            <Typography variant="body_1">🔍&nbsp;&nbsp; 평가사가 진단 후 경매등록까지 알아서</Typography>
            <Typography variant="body_1">💰&nbsp;&nbsp; 현장감가 없이 경매 최고가로 팔아요.</Typography>
            <Typography variant="body_1">👌&nbsp;&nbsp; 딜러를 만날 필요가 없어요.</Typography>
          </div>
          <div css={zeroPriceStyle}>
            <Typography variant="headline_1">🎉</Typography>
            <div css={{ textAlign: "center" }}>
              <Typography variant="subtitle_1">
                <span css={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={zero}
                    alt="제로"
                    css={{ width: "3rem", height: "2rem", objectFit: "contain", marginRight: "0.25rem" }}
                  />
                  런칭기념 <br />
                </span>
                이용료 무료
              </Typography>
            </div>
            <div css={{ display: "flex", alignItems: "center" }}>
              <div css={{ marginRight: "0.5rem" }}>
                <Typography variant="body_1" color="white_7" inline css={{ textDecorationLine: "line-through" }}>
                  29,000원
                </Typography>
              </div>
              <div>
                <Typography variant="headline_1" color="brand_2" inline>
                  0
                </Typography>
                <Typography variant="headline_2" color="brand_2" inline>
                  원
                </Typography>
              </div>
            </div>
          </div>
        </section>
        <section css={{ padding: `1rem 2rem 2.5rem`, overflow: "hidden" }} ref={ref}>
          <Typography variant="subtitle_1" customCSS={{ marginBottom: "1.5rem" }}>
            <span css={customerCountStyle}>
              <animated.span>{countAnimation.count.to((number) => Math.round(number).toLocaleString())}</animated.span>
            </span>
            명의 고객님이 <br />
            거래 완료했어요.
          </Typography>
          <ReviewCarousel />
        </section>
        <section css={progressSectionStyle}>
          <Progress title="진행과정" items={progress} />
        </section>
        <내차팔기버튼 deeplinkUrl={data?.deeplink_url} />
      </div>
    </PageWrapper>
  );
};

export default ZeroPage;
