import { css } from "@emotion/react";

export const sectionWrapperStyle = css`
  display: grid;
  gap: 1.5rem;

  box-sizing: border-box;

  padding: 1.5rem;
`;

export const sectionTextAreaStyle = css`
  display: grid;
  gap: 0.5rem;
`;

export const tableRowStyle = css`
  display: flex;
  justify-content: space-between;

  padding: 0.5rem 0;
`;

export const imageWrapperStyle = css`
  width: 100%;

  margin: 0 auto;

  border-radius: 0.25rem;

  overflow: hidden;

  img {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 100%;
    height: 100%;

    transform: translate(-50%, -50%);

    object-fit: cover;
  }
`;
