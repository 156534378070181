import type { FC } from "react";
import React, { useState } from "react";

import { map } from "lodash";

import FaqCollapse from "../FaqCollapse/FaqCollapse";

import styles from "./FAQ.module.scss";

const faqs = [
  {
    title: "무료픽업 전 판매취소가 가능한가요?",
    content: "네 가능합니다. \n사정이 생겨 판매가 어려우시면, 픽업 3시간 전까지 취소해주세요.",
  },
  {
    title: "탁송이나 진단 중 혹시 사고가 발생하면?",
    content:
      "헤이딜러와 탁송 보험회사가 모두 책임지기 때문에 걱정하지 않으셔도 됩니다.\n 진단관련 책임보험도 가입되어 있으니, 혹시 모를 사고에서 고객님의 피해는 생기지 않습니다.",
  },
  {
    title: "명의이전과 송금은 얼마나 걸리나요?",
    content:
      "영업시간 기준 평균 1시간 내에 완료됩니다.\n온라인 명의이전(차주 본인 핸드폰 인증필요)과 송금 에스크로까지 헤이딜러가 안전하게 진행합니다.\n다만 압류, 저당이 남아있는 경우, 해제에 시간이 소요될 수 있습니다.\n(상세한 내용은 헤이딜러 매니저가 통화로 안내)",
  },
];

const Faq: FC = () => {
  const [openCollapse, setOpenCollapse] = useState<number | null>(0);

  return (
    <>
      <div className={styles.border} />
      <div className={styles.faq}>
        {map(faqs, (faq, index) => {
          return (
            <FaqCollapse
              title={faq.title}
              content={faq.content}
              collapseNumber={index}
              openCollapse={openCollapse}
              setOpenCollapse={setOpenCollapse}
            />
          );
        })}
      </div>
    </>
  );
};

export default Faq;
