import type { FC, PropsWithChildren, ReactNode } from "react";
import React, { memo } from "react";

import classNames from "classnames";
import { get } from "lodash";

import styles from "./Typography.module.scss";

type Variant =
  | "headline_xl_m"
  | "headline_l_m"
  | "headline_m"
  | "body_l_m"
  | "body_m"
  | "caption_m"
  | "small_text_m"
  | "subtitle_l_m"
  | "subtitle_m"
  | "subtitle_s_m"
  | "headline_lt_m"
  | "headline_xl"
  | "headline_l"
  | "headline"
  | "headline_lt"
  | "subtitle_l"
  | "subtitle"
  | "subtitle_s"
  | "caption"
  | "small_text"
  | "body_l"
  | "body"
  | "bodyMulti"
  | "material_light_headline"
  | "subtitle_xs"
  | "Headline_40"
  | "Headline_32"
  | "Headline_24"
  | "Headline_20"
  | "Headline_24_L"
  | "Subtitle_20"
  | "Subtitle_16"
  | "Subtitle_14"
  | "Subtitle_12"
  | "Body_16"
  | "Body_14"
  | "Caption";

type Props = PropsWithChildren<{
  variant: Variant;
  children?: ReactNode;
  className?: string;
}>;

const Typography: FC<Props> = memo(({ className, variant, children }) => {
  const tagMap: { [key in Variant]?: string } = {
    Headline_40: "h1",
    Headline_24: "h2",
    Headline_20: "h3",
    Subtitle_16: "h4",
    Body_16: "p",
    Body_14: "p",
  };

  const tag = React.createElement(
    tagMap[variant] || "span",
    { className: classNames(styles.typography, get(styles, variant), className) },
    children
  );

  return <>{tag}</>;
});

export default Typography;
