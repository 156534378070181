const faqs = [
  {
    title: "헤이딜러 에스크로 계좌 입금은 문제없나요?",
    content: [
      "네, 모든 세무 증빙이 제공되므로 걱정하지 않으셔도 됩니다.",
      "또 헤이딜러가 차주 계좌여부를 확인 후 안전하게 입금하며, 송금관련 사고는 헤이딜러가 책임집니다.",
      "(대기업에서도 에스크로 계좌로 입금 중입니다. 또한 사업자+비용처리 차량은 계산서가 상사로 정상 발급됩니다.)",
      "",
      "선입금이 필요한 이유는 탁송 시점에 고객에게 자동 송금하기 위함입니다.",
      "(빠르게 입금주셔야 헤이딜러 고객 매니저가 탁송일정을 최대한 당길 수 있습니다)",
    ].join("\n"),
  },
  {
    title: "고객과 직접 통화가 가능할까요?",
    content: [
      "운영 시스템 상 불가능 합니다.",
      "회원딜러 분들의 업무편의를 고려해 설계된 운영 시스템이니 양해 부탁드립니다.",
      "",
      "인수한 차량에 진단오류가 있다면, 인수 후 클레임으로 해결이 가능합니다.",
    ].join("\n"),
  },
  {
    title: "사업자 차량은 어떻게 처리되나요?",
    content: [
      "국세청 가이드와 대기업 세무처리 기준에 맞춰 FM으로 처리됩니다.",
      "",
      "먼저 헤이딜러에서 사업자 유무를 조회합니다.",
      "고객 상담 및 서류 확인을 통해, 업무용으로 비용처리한 고객분께는 상사로 계산서 발급을 요청드립니다.",
      "비사업용 차량은 헤이딜러에서 비사업용 여부를 서류로 확인합니다.",
      "확인한 서류와 고객 분께 추가로 받은 비사업용 사실확인서는 딜러분께 전달드립니다.",
      "",
      "거래 후 [거래 주요정보] 버튼을 눌러, 확인 후 PDF를 다운받으실 수 있습니다.",
    ].join("\n"),
  },
  {
    title: "제로 차량이 보이지 않습니다.",
    content: [
      "적정 입찰 수를 유지하기 위해, 계신 지역이나 매매단지에 따라 선별적으로 노출됩니다.",
      "제로 차량이 보이지 않는다면, 양해 부탁드립니다.",
      "매월 1회 노출 기준이 자동 변경되니, 조금만 기다려주세요.",
    ].join("\n"),
  },
];

export default faqs;
