import React from "react";

import { Typography } from "@PRNDcompany/heydealer-ui";

import styles from "./ProgressTitle.module.scss";

interface Props {
  step: number;
  title: string;
}

const ProgressTitle: React.FC<Props> = ({ step, title }) => {
  return (
    <div className={styles.titleWrapper}>
      <span className={styles.step}>{step}</span>
      <Typography variant="subtitle_3" color="brand_2">
        {title}
      </Typography>
    </div>
  );
};

export default ProgressTitle;
