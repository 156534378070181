import { createRef, useEffect, useState } from "react";

const useVideoBackground = (defaultBackground = "") => {
  const canvasRef = createRef<HTMLCanvasElement>();
  const videoRef = createRef<HTMLVideoElement>();

  const [background, setBackground] = useState<string>(defaultBackground);

  const createFrameRequest = (
    canvas: HTMLCanvasElement,
    video: HTMLVideoElement,
    ctx: CanvasRenderingContext2D
  ): FrameRequestCallback => {
    const frameRequest: FrameRequestCallback = () => {
      window.requestAnimationFrame(frameRequest);
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      setBackground(`rgba(${ctx.getImageData(0, 0, 1, 1).data.slice(0, 4).join(",")})`);
    };

    return frameRequest;
  };

  const onVideoLoad = async () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    const ctx = canvas?.getContext("2d", { willReadFrequently: true });
    if (!canvas || !video || !ctx) {
      return;
    }

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    if (video.paused) {
      try {
        await video.play();
      } catch (e) {
        const poster = new Image();
        poster.src = video.poster;
        canvas.width = poster.width;
        canvas.height = poster.height;
        ctx.drawImage(poster, 0, 0, canvas.width, canvas.height);
      }
    }
  };

  const onPlay = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    const ctx = canvas?.getContext("2d", { willReadFrequently: true });
    if (!canvas || !video || !ctx) {
      return;
    }

    window.requestAnimationFrame(createFrameRequest(canvas, video, ctx));
  };

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    videoRef.current.onloadeddata = onVideoLoad;
    videoRef.current.onplay = onPlay;
  }, [videoRef]);

  return { canvasRef, videoRef, background };
};

export default useVideoBackground;
