import React from "react";

import PageWrapper from "../../components/PageWrapper/PageWrapper";

import BodySection from "./BodySection/BodySection";
import FAQSection from "./FAQSection/FAQSection";
import HeadSection from "./HeadSection/HeadSection";

import styles from "./PreInspectedCarProgressDescriptionForDealerPage.module.scss";

interface Props {}

const PreInspectedCarProgressDescriptionForDealerPage: React.FC<Props> = () => {
  return (
    <PageWrapper title={"진단완료 차량 거래과정"}>
      <div className={styles.preInspectedCarProgressDescriptionForDealerPage}>
        <div className={styles.container}>
          <HeadSection />
          <hr className={styles.hr1} />
          <BodySection />
        </div>
        <div className={styles.hr2} />
        <FAQSection />
      </div>
    </PageWrapper>
  );
};

export default PreInspectedCarProgressDescriptionForDealerPage;
