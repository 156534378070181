import React from "react";

import { colors } from "@PRNDcompany/heydealer-ui";
import Ink from "react-ink";
import { ClipLoader } from "react-spinners";

import { ReactComponent as UploadIcon } from "../../../../images/customerIdentification/upload.svg";
import Typography from "../../../Typography/Typography";

import styles from "./EmptyFile.module.scss";

interface Props {
  onCreate: (files: FileList | null) => void;
  isLoading: boolean;
}

const EmptyFile: React.FC<Props> = ({ onCreate, isLoading }) => {
  return (
    <label className={styles.createLabel}>
      {isLoading ? (
        <ClipLoader color={colors.brand_2} size={28} />
      ) : (
        <>
          <div className={styles.labelWrapper}>
            <Typography variant="Subtitle_16" className={styles.label}>
              <UploadIcon className={styles.icon} />
              사진 선택
            </Typography>
            <Typography variant="Caption" className={styles.info}>
              주민등록증, 운전면허증, 여권,
              <br /> 또는 기타 정부발급 신분증 앞면 1장
            </Typography>
          </div>
          <Ink />
          <input
            className={styles.fileInput}
            type="file"
            accept="image/x-png,image/gif,image/jpeg,image/heic,image/heif"
            multiple
            onChange={(e) => onCreate(e.currentTarget.files)}
          />
        </>
      )}
    </label>
  );
};

export default EmptyFile;
