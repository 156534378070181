import { css } from "@emotion/react";
import { colors } from "@PRNDcompany/heydealer-ui";

export const 평가사가뭘진단하나요ButtonStyle = css`
  display: grid;
  justify-content: start;
  align-items: center;
  gap: 0.25rem;

  width: fit-content;

  padding: 0.5rem 0.75rem;

  border-radius: 0.25rem;

  background: ${colors.brand_2_alpha_8};
  color: ${colors.brand_2};

  grid-template-columns: 1fr 1rem;
  cursor: pointer;
`;
