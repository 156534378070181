/** @jsxImportSource @emotion/react */

import type { FC } from "react";

import { css } from "@emotion/react";
import { colors, Typography } from "@PRNDcompany/heydealer-ui";

import useVideoBackground from "../../../hooks/useVideoBackground";
import reduction from "../assets/img/reduction.mp4";

const ReductionCenterSection: FC = () => {
  const { background, canvasRef, videoRef } = useVideoBackground(colors.white_3);

  return (
    <div css={{ background }}>
      <div
        css={css`
          display: grid;
          gap: 0.5rem;

          box-sizing: border-box;
          width: 100%;

          padding: 1.25rem 1.5rem 0;
        `}
      >
        <Typography variant="headline_3">감가심사센터</Typography>
        <Typography variant="body_1">
          헤이딜러는 고객이 부당 감가 없이 합당한 금액으로 거래할 수 있도록 철저한 검토 과정을 거칩니다.
        </Typography>
      </div>
      <div css={{ display: "flex", justifyContent: "center" }}>
        <video autoPlay muted playsInline loop ref={videoRef} src={reduction} hidden />
        <canvas ref={canvasRef} css={{ width: "17.5rem", height: "17.5rem" }} />
      </div>
    </div>
  );
};

export default ReductionCenterSection;
