import taxios from "@PRNDcompany/taxios";

import type { Car } from "../../types";

export const createGetCarAPI = (car_hash_id: string) => async () => {
  return (await taxios.get<Car>(`/v2/customers/cars/${car_hash_id}/`)).data;
};

export const createPatchCarPreInspectionAPI = (car_hash_id: string) => async (payload: { detail_address: string }) => {
  return (await taxios.patch(`/v2/customers/cars/${car_hash_id}/pre_inspection/`, payload)).data;
};
