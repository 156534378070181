/** @jsxImportSource @emotion/react */

import type { FC } from "react";

import { css } from "@emotion/react";
import { colors } from "@PRNDcompany/heydealer-ui";

const containerStyle = css`
  display: inline-block;

  width: 0.125rem;
  height: 0.125rem;

  border-radius: 50%;

  background: ${colors.white_5};
`;

const DotLine: FC = () => {
  return <span css={containerStyle} />;
};

export default DotLine;
