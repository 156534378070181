/** @jsxImportSource @emotion/react */

import type { FC } from "react";
import { useEffect, useLayoutEffect } from "react";

import { close, setStatusBar, isAppWebview, isCustomerAppWebview } from "@PRNDcompany/heydealer-lib";
import {
  DeprecatedAppBar as AppBar,
  colors,
  Information,
  StrokeCloseIcon,
  Typography,
} from "@PRNDcompany/heydealer-ui";
import { useLocation } from "react-router";

import Page from "../../components/Page";
import useScrollPosition from "../../hooks/useScrollPosition";
import { logVisitReductionStandardContentEvent } from "../customer/modules/logEvent";

import reportBg from "./assets/bg/report.png";
import CarFrameSection from "./components/CarFrameSection";
import EtcSection from "./components/EtcSection";
import ExteriorSection from "./components/ExteriorSection";
import WheelSection from "./components/WheelSection";
import { i18n } from "./data/i18n";
import { useTranslation } from "./useTranslation";

const ReductionStandardPage: FC = () => {
  const scrollPosition = useScrollPosition();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const lang = searchParams.get("lang") || "ko";

  const { t, configure } = useTranslation();

  useLayoutEffect(() => {
    if (isCustomerAppWebview) {
      logVisitReductionStandardContentEvent();
    }
  }, []);

  useLayoutEffect(() => {
    if (isAppWebview) {
      setStatusBar({ color: scrollPosition <= 0 ? colors.white_3 : colors.white_1, content_brightness: "dark" });
    }
  }, [scrollPosition]);

  useEffect(() => {
    configure(lang, i18n);
  }, [lang, i18n]);

  return (
    <div dir="auto">
      <Page>
        {isAppWebview && (
          <AppBar
            leftIcon={{ icon: StrokeCloseIcon, onClick: close }}
            fixed
            background={scrollPosition <= 0 ? "transparent" : `${colors.white_1}E6`}
          />
        )}
        <div
          css={{
            height: "15rem",
            background: `url("${reportBg}") center / auto 100% no-repeat, ${colors.white_3}`,
          }}
        />
        <div css={{ display: "grid", gap: "1rem", padding: "1.5rem" }}>
          <div css={{ display: "grid", gap: "0.5rem" }}>
            <Typography variant="headline_3">{t("현장감가 기준")}</Typography>
            <Typography variant="body_1">
              {t("현장 검차시 미리 고지하지 않은 감가 요소가 추가로 발견된다면, 감가될 수 있어요.")}
              {t("감가금액은 아래 기준에 따라 제한하고 있어요.")}
            </Typography>
          </div>
          <Information type="highlight" title={t("걱정하지 마세요")}>
            <ul>
              <li>{t("차량의 상태와 사고이력을 정확히 입력했다면, 현장감가는 발생되지 않아요.")}</li>
              <li>{t("만약 미리 고지한 내용으로 감가됐다면, 본사에서 책임지고 환급해드려요.")}</li>
            </ul>
          </Information>
        </div>
        <div css={{ display: "grid", gap: "2.5rem", padding: "1.5rem 0" }}>
          <ExteriorSection />
          <CarFrameSection />
          <WheelSection />
          <EtcSection />
        </div>
      </Page>
    </div>
  );
};

export default ReductionStandardPage;
