import React from "react";

import classNames from "classnames";

import styles from "./Border.module.scss";

interface Props {
  height?: number;
  className?: string;
}

const Border: React.FC<Props> = ({ height = 1, className }) => {
  return <div className={classNames(styles.border, className)} style={{ borderBottomWidth: `${height}px` }} />;
};

export default Border;
