import taxios from "@PRNDcompany/taxios";

import { BASE_PATH } from "../BASE_PATH";

type GetMechanicInspectionVideosResponse = {
  url: string;
  thumbnail_image_url: string;
  description: string;
}[];

export const getMechanicInspectionVideosAPI = async () => {
  return (await taxios.get<GetMechanicInspectionVideosResponse>(`${BASE_PATH}/mechanic_inspection_videos/`)).data;
};
