/** @jsxImportSource @emotion/react */

import type { FC } from "react";

import { Typography, Divider } from "@PRNDcompany/heydealer-ui";

import AspectRatio from "../../../components/AspectRatio";
import WheelImg from "../assets/img/wheel.png";
import { imageWrapperStyle, sectionTextAreaStyle, sectionWrapperStyle, tableRowStyle } from "../styles";
import { useTranslation } from "../useTranslation";

const WheelSection: FC = () => {
  const { t } = useTranslation();

  return (
    <div css={sectionWrapperStyle}>
      <div css={sectionTextAreaStyle}>
        <Typography variant="subtitle_1">{t("타이어, 휠 손상")}</Typography>
        <div>
          <Typography variant="body_1">
            {t("지면에 직접 닿는 부위인 만큼 휠 손상, 타이어 마모도 흔하게 발견돼요.")}
          </Typography>
        </div>
      </div>
      <div css={imageWrapperStyle}>
        <AspectRatio heightPerWidth={0.5}>
          <img src={WheelImg} />
        </AspectRatio>
      </div>
      <div css={{ display: "grid", gap: "1rem" }}>
        <div>
          <div css={tableRowStyle}>
            <Typography variant="subtitle_3">{t("타이어 트레드 20% 미만")}</Typography>
            <Typography variant="body_2">{t("9 ~ 12만원 /짝")}</Typography>
          </div>
          <Divider />
          <div css={tableRowStyle}>
            <Typography variant="subtitle_3">{t("휠 복원 (일반)")}</Typography>
            <Typography variant="body_2">{t("5 ~ 10만원 /짝")}</Typography>
          </div>
          <Divider />
          <div css={tableRowStyle}>
            <Typography variant="subtitle_3">{t("휠 복원 (다이아 컷팅)")}</Typography>
            <Typography variant="body_2">{t("8 ~ 13만원 /짝")}</Typography>
          </div>
          <Divider />
          <div css={tableRowStyle}>
            <Typography variant="subtitle_3">{t("휠 복원 (크롬)")}</Typography>
            <Typography variant="body_2">{t("10 ~ 20만원 /짝")}</Typography>
          </div>
        </div>
        <Typography variant="caption_1" color="white_7">
          <ul>
            <li>{t("17~18인치 기준으로, 19인치 이상은 수리비가 올라가요.")}</li>
            <li>{t("스포츠 타이어, 수입 타이어는 수리비가 올라가요.")}</li>
          </ul>
        </Typography>
      </div>
    </div>
  );
};

export default WheelSection;
