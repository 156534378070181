import React from "react";

import classNames from "classnames";
import { useInView } from "react-intersection-observer";

import styles from "./Process.module.scss";

interface Props {
  title: string;
  desc: string;
  image: string;
  info?: string;
}

const Process: React.FC<Props> = ({ title, desc, image, info }) => {
  const [imageRef, imageInView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <div className={classNames(styles.process, styles.dot)}>
      <h2>{title}</h2>
      <div className={styles.desc}>{desc}</div>
      {info && <div className={styles.info}>{info}</div>}
      <img
        src={image}
        alt={title}
        className={classNames(styles.image, { [styles.view]: imageInView })}
        ref={imageRef}
      />
      <div className={styles.bigDot} />
      <svg style={{ width: "100%", height: "100%" }} className={styles.dotted}>
        <line
          x1="5"
          x2="5"
          y1="-2"
          y2="100%"
          stroke="#396eff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeDasharray="1 12"
          strokeDashoffset="0"
          className={styles.line}
        />
      </svg>
    </div>
  );
};

export default Process;
