/** @jsxImportSource @emotion/react */
import React, { useState } from "react";

import { Button, colors } from "@PRNDcompany/heydealer-ui";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import { map, size, get } from "lodash";
import { useParams } from "react-router";
import { ClipLoader } from "react-spinners";

import pxios from "../../apis/pxios";
import ImageInputList from "../../components/CustomerIdentification/ImageInputList/ImageInputList";
import Layout from "../../components/Layout/Layout";
import Emoji from "../../components/MortgageAndAttachment/Emoji/Emoji";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import Typography from "../../components/Typography/Typography";
import { setCarToken } from "../../cores/setCarToken";
import type { ImageInput } from "../../declaration/server";
import Checkbox from "../../design-system/Form/Selector/Checkbox/Checkbox";
import AllDone from "../SurveyPage/AllDone/AllDone";

import styles from "./CustomerIdentification.module.scss";

const CustomerIdentification: React.FC = () => {
  const { hashId } = useParams<{ hashId: string }>();
  const [isCheckedPrivacyPolicy, setCheckedPrivacyPolicy] = useState(false);

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ["car_images", hashId],
    queryFn: () =>
      pxios.get<{ is_inputted: boolean }>(`/v2/customers/web/cars/${hashId}/id_card_images/`, setCarToken()),
  });

  const idCardImagesMutation = useMutation({
    mutationKey: ["mortgage"],
    mutationFn: (ids: number[]) => {
      return pxios.patch(`/v2/customers/web/cars/${hashId}/id_card_images/`, { id_card_images: ids }, setCarToken());
    },
    onSuccess: () => {
      queryClient.invalidateQueries([`car_images`]);
    },
  });

  const { values, setFieldValue, handleSubmit } = useFormik<{ images: ImageInput[] }>({
    initialValues: {
      images: [],
    },
    onSubmit: async (values) => {
      idCardImagesMutation.mutate(map(values.images, "id"));
    },
  });

  const onChangeImages = (images: ImageInput[]) => {
    setFieldValue("images", images);
  };

  const isInputted = get(data, ["data", "is_inputted"]);

  if (isLoading) {
    return (
      <PageWrapper title={"신분증 업로드"}>
        <Layout className={styles.customerIdentification}>
          <div className={styles.loaderWrapper}>
            <ClipLoader color={colors.brand_2} />;
          </div>
        </Layout>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper title={"신분증 업로드"}>
      <Layout className={styles.customerIdentification}>
        {isInputted ? (
          <AllDone
            title={
              <>
                고객님의 신분증이 <br />잘 등록됐어요.
              </>
            }
            description={<>추가 진행상황은 알림톡으로 다시 안내드릴게요.</>}
          />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className={styles.section1}>
              <Typography variant="Headline_24">신분증 사진을 올려주세요.</Typography>
              <Typography variant="Body_16" className={styles.description}>
                고객님 차량은 해외 수출 예정으로, 말소등록 절차를 거치게 되는데요. 이 때 차량 소유주의 신분증 사본이 꼭
                필요해요. <Emoji name={"white-up-pointing-index"} size={16} />
              </Typography>
              <Typography variant="Body_14" className={styles.info}>
                * 공동명의라면, 명의자분 모두의 신분증 사진 필요
              </Typography>
              <div className={styles.imageInputWrapper}>
                <ImageInputList
                  images={values.images}
                  onChangeImages={onChangeImages}
                  hashId={hashId}
                  uploadPath={"car_images"}
                />
              </div>
              <Typography variant="Body_14" className={styles.notice}>
                <Emoji name={"white-up-pointing-index"} size={14} /> 걱정 마세요. 관공서 제출용으로만 사용되고 <br />{" "}
                말소등록 후에는 즉시 폐기됩니다.
              </Typography>
            </div>
            <div className={styles.border} />
            <div className={styles.section2}>
              <Typography variant="Subtitle_16" className={styles.subTitle}>
                개인정보 제 3자 제공 동의
              </Typography>
              <Typography variant="Body_14" className={styles.privacyPolicy}>
                제공받는자: 차량을 매입 예정인 매매상사 <br />
                이용목적: 해외 수출 목적의 말소등록 <br />
                보유기간: 이용목적 달성 후 즉시 폐기
              </Typography>
              <Checkbox
                label="네, 동의합니다."
                variant="box"
                className={styles.privacyPolicyCheck}
                checked={isCheckedPrivacyPolicy}
                onChange={(e) => {
                  setCheckedPrivacyPolicy(e.currentTarget.checked);
                }}
              />
            </div>
            <div css={{ padding: "0 1rem" }}>
              <Button type="submit" disabled={!(isCheckedPrivacyPolicy && size(values.images) > 0)} fullWidth>
                신분증 업로드 완료
              </Button>
            </div>
          </form>
        )}
      </Layout>
    </PageWrapper>
  );
};

export default CustomerIdentification;
