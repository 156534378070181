import React, { useState } from "react";

import classNames from "classnames";

import Typography from "../../components/Typography/Typography";

import InfoModal from "./InfoModal/InfoModal";
import type { Box } from "./SellRequiredDocsPage";

import boxStyles from "./Box.module.scss";
import styles from "./SellRequiredDocsPage.module.scss";

interface Props {
  data: Box;
}

interface InnerProps {
  data: Box;
  index: number;
}

export const GenerateJSX: React.FC<Props> = ({ data }) => {
  const GenJSXRec: React.FC<InnerProps> = ({ data, index }): JSX.Element => {
    const [clicked, setClicked] = useState(false);
    const children = data.contents && data.contents.map((item, i) => <GenJSXRec data={item} index={i} key={i} />);
    switch (data.type) {
      case "sub":
        return (
          <div className={boxStyles.sub} key={index}>
            <div className={classNames(boxStyles.subTitleWrap, styles.flex)}>
              <Typography variant={"subtitle_s"} className={styles.subtitleBlue}>
                {data.title}
              </Typography>
              {data.info && (
                <div
                  className={classNames(styles.info, clicked ? boxStyles.show : boxStyles.hide)}
                  onClick={() => setClicked((prev) => !prev)}
                >
                  {data.info}
                </div>
              )}
            </div>
            {children}
          </div>
        );
      case "cap":
        return (
          <div className={boxStyles.cap} key={index}>
            <div className={boxStyles.capTitleWrap}>
              <Typography variant={"subtitle_xs"} className={styles.captionNavy10}>
                {data.title}
              </Typography>
            </div>
            {children}
          </div>
        );
      case "con":
        return (
          <div className={boxStyles.con} key={index}>
            <div className={classNames(boxStyles.conTitleWrap)}>
              <Typography variant={"body"}>{data.title}</Typography>
              {data.info && <Typography variant={"caption"}>{data.info}</Typography>}
            </div>
          </div>
        );
      case "hr":
        return <hr className={boxStyles.boxSplit} key={index} />;
      case "box":
        return <div>ERROR</div>;
    }
  };

  const contents = data.contents && data.contents.map((item, i) => <GenJSXRec data={item} index={i} key={i} />);

  return <>{contents}</>;
};

export const entireContents: Box[] = [
  {
    title: "간이 과세자",
    type: "box",
    contents: [
      { title: "1. 자동차등록증 원본", type: "con" },
      { title: "2. 자동차매도용 인감증명서", type: "con", info: "(매수자 인적사항 기재)" },
      { title: "3. 사업자등록증 사본", type: "con" },
    ],
  },
  {
    title: "일반 과세자",
    type: "box",
    contents: [
      {
        title: "사업용 차량",
        type: "sub",
        info: <InfoModal type={"work"} />,
        contents: [
          { title: "1. 자동차등록증 원본", type: "con" },
          { title: "2. 자동차매도용 인감증명서", type: "con", info: "(매수자 인적사항 기재)" },
          { title: "3. 사업자등록증 사본", type: "con" },
          { title: "4. 세금계산서", type: "con", info: "(부가세 포함)" },
        ],
      },
      { type: "hr" },
      {
        title: "비사업용 차량",
        type: "sub",
        info: <InfoModal type={"notWork"} />,
        contents: [
          {
            title: "공통서류",
            type: "cap",
            contents: [
              { title: "1. 자동차등록증 원본", type: "con" },
              { title: "2. 자동차매도용 인감증명서", type: "con", info: "(매수자 인적사항 기재)" },
              { title: "3. 일반 인감증명서", type: "con", info: "(사실확인서 증빙용)" },
              { title: "4. 사업자등록증 사본", type: "con" },
              { title: "5. 비사업용 사실확인서", type: "con", info: "(세무사 명판 날인)" },
            ],
          },
          {
            title: "복식부기인 경우",
            type: "cap",
            contents: [
              { title: "6. 표준재무상태표 또는 합계잔액시산표", type: "con" },
              { title: "7. 차량운반구 감가상각비명세서 or 고정자산명세서", type: "con" },
            ],
          },
          {
            title: "간편장부인 경우",
            type: "cap",
            contents: [
              { title: "6. 간편장부소득금액계산서", type: "con" },
              { title: "7. 총 수입금액 및 필요경비명세서", type: "con" },
            ],
          },
        ],
      },
    ],
  },
  {
    title: "면세 사업자",
    type: "box",
    contents: [
      {
        title: "사업용 차량",
        type: "sub",
        info: <InfoModal type={"work"} />,
        contents: [
          { title: "1. 자동차등록증 원본", type: "con" },
          { title: "2. 자동차매도용 인감증명서", type: "con", info: "(매수자 인적사항 기재)" },
          { title: "3. 사업자등록증 사본", type: "con" },
          { title: "4. 면세계산서", type: "con", info: "(부가세 면제)" },
        ],
      },
      {
        type: "hr",
      },
      {
        title: "비사업용 차량",
        type: "sub",
        info: <InfoModal type={"notWork"} />,
        contents: [
          {
            title: "공통서류",
            type: "cap",
            contents: [
              { title: "1. 자동차등록증 원본", type: "con" },
              { title: "2. 자동차매도용 인감증명서", type: "con", info: "(매수자 인적사항 기재)" },
              { title: "3. 일반 인감증명서", type: "con", info: "(사실확인서 증빙용)" },
              { title: "4. 사업자등록증 사본", type: "con" },
              { title: "5. 비사업용 사실확인서", type: "con", info: "(세무사 명판 날인)" },
            ],
          },
          {
            title: "복식부기인 경우",
            type: "cap",
            contents: [
              { title: "6. 표준재무상태표 또는 합계잔액시산표", type: "con" },
              { title: "7. 차량운반구 감가상각비명세서 or 고정자산명세서", type: "con" },
            ],
          },
          {
            title: "간편장부인 경우",
            type: "cap",
            contents: [
              { title: "6. 간편장부소득금액계산서", type: "con" },
              { title: "7. 총 수입금액 및 필요경비명세서", type: "con" },
            ],
          },
        ],
      },
    ],
  },
  {
    title: "법인 사업자",
    type: "box",
    contents: [
      { title: "1. 자동차등록증 원본", type: "con" },
      { title: "2. 자동차매도용 인감증명서", type: "con", info: "(매수자 인적사항 기재)" },
      { title: "3. 사업자등록증 사본", type: "con" },
      { title: "4. 세금계산서", type: "con", info: "(부가세포함)" },
      { title: "5. 법인 등기부등본", type: "con", info: "(15일 이내 발급, 말소사항 포함)" },
      { title: "6. 양도계약서", type: "con", info: "(추후 안내)" },
    ],
  },
];
