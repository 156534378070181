import type { FC } from "react";
import React, { useState } from "react";

import { ResizeObserver } from "@juggle/resize-observer";
import { ArrowDownIcon, ArrowUpIcon, Typography } from "@PRNDcompany/heydealer-ui";
import classNames from "classnames";
import Ink from "react-ink";
import useMeasure from "react-use-measure";

import ShowInView from "../../../../components/ShowInView";
import { ReactComponent as Safe } from "../../assets/ic/safe.svg";
import CommentImg from "../../assets/img/comment.png";

import Process from "./Process";

import styles from "./SafeTradeSection.module.scss";

const processes = [
  {
    title: "판매결정",
    desc: "차주명의 계좌번호를 입력하세요.",
  },
  {
    title: "딜러 에스크로 입금",
    desc: "차대금은 헤이딜러에 안전하게 보관됩니다.",
  },
  {
    title: "탁송기사 도착",
    desc: "기사가 서류확인 후 헤이딜러에 전화합니다.",
  },
  {
    title: "내 계좌로 이체",
    desc: "헤이딜러 매니저가 고객님 계좌로 입금해요.",
  },
];

const SafeTradeSection: FC = () => {
  const [isOpened, setOpen] = useState(false);
  const [ref, { height }] = useMeasure({ polyfill: ResizeObserver });

  const contentWrapperInlineStyle = {
    ...(isOpened ? { height: height + 56 } : { height: 56 }),
  };

  return (
    <ShowInView className={classNames(styles.safeTradeSection)}>
      <img src={CommentImg} alt={"코멘트"} className={styles.commentImage} />
      <div className={styles.content}>
        <Typography variant="subtitle_1">
          누적 거래대수 90만대 <br />
          헤이딜러 믿고 판매하세요.
        </Typography>
        <div
          className={classNames(styles.container, { [styles.openContainer]: isOpened })}
          style={contentWrapperInlineStyle}
        >
          <button
            className={classNames(styles.safeTradeButton, { [styles.openSafeTradeButton]: isOpened })}
            onClick={() => setOpen((prev) => !prev)}
          >
            <div className={styles.titleWrapper}>
              <Safe className={styles.safeIcon} />
              <Typography variant="body_1">헤이딜러 안심거래</Typography>
            </div>
            {isOpened ? <ArrowUpIcon size={16} color="brand_2" /> : <ArrowDownIcon size={16} color="white_6" />}
            <Ink />
          </button>
          <div className={classNames(styles.content, { [styles.open]: isOpened })} ref={ref}>
            <div className={styles.tradeProcess}>
              {processes.map((process) => (
                <Process key={process.title} title={process.title} desc={process.desc} />
              ))}
              <div className={styles.line} />
            </div>
            <Typography variant="caption_1" color="black_6">
              ※ 거래가 몰리는 시간에는 입금이 조금 늦어질 수 있어요. 입금이 조금 늦어진다면, 탁송을 먼저 출발시켜도
              괜찮습니다. 최대 20분 내에는 신속하게 입금해드릴게요!
            </Typography>
          </div>
        </div>
      </div>
    </ShowInView>
  );
};

export default SafeTradeSection;
