import taxios from "@PRNDcompany/taxios";

import { setCarToken } from "../../../../cores/setCarToken";
import type { Car, PaperPreparationInfo, BusinessDocument, BusinessExpenseType, Owner, ExpensedCar } from "../../types";
import { BASE_PATH } from "../BASE_PATH";

export const createGetCarAPI = (hashId: Car["hash_id"]) => async () => {
  return (await taxios.get<Car>(`${BASE_PATH}/cars/${hashId}/`)).data;
};

export const createGetCarPaperPreparationInfoAPI = (hashId: Car["hash_id"]) => async () => {
  return (await taxios.get<PaperPreparationInfo>(`${BASE_PATH}/cars/${hashId}/paper_preparation_info/`, setCarToken()))
    .data;
};

export const postBusinessDocumentFilesAPI = async (form: FormData) => {
  return (await taxios.post(`${BASE_PATH}/business_document_files/`, form, setCarToken())).data;
};

export const deleteBusinessDocumentFileAPI = async (fileId: number) => {
  return (await taxios.delete(`${BASE_PATH}/business_document_files/${fileId}/`, setCarToken())).data;
};

export const createPostBusinessDocumentSubmitAPI = (id: BusinessDocument["id"]) => async () => {
  return (await taxios.post(`${BASE_PATH}/business_documents/${id}/submit/`, null, setCarToken())).data;
};

export const createPostCarAgreeToTaxInvoiceIssuanceAPI =
  (hashId: Car["hash_id"]) => async (payload: { business_expense_type: BusinessExpenseType }) => {
    return (await taxios.post(`${BASE_PATH}/cars/${hashId}/agree_to_tax_invoice_issuance/`, payload, setCarToken()))
      .data;
  };

type PostCarRequestNoBusinessExpenseConfimrationAPIPayload = {
  owners: (Pick<Owner, "phone_number"> & { owner: Owner["hash_id"] })[];
} & (ExpensedCar | Record<string, unknown>);

export const createPostCarRequestNoBusinessExpenseConfirmationAPI =
  (hashId: Car["hash_id"]) => async (payload: PostCarRequestNoBusinessExpenseConfimrationAPIPayload) => {
    return (
      await taxios.post(`${BASE_PATH}/cars/${hashId}/request_no_business_expense_confirmation/`, payload, setCarToken())
    ).data;
  };
