/** @jsxImportSource @emotion/react */
import type { FC } from "react";

import { Divider, Typography } from "@PRNDcompany/heydealer-ui";

import { sectionWrapperStyle, sectionTextAreaStyle, tableRowStyle } from "../../styles";
import { useTranslation } from "../../useTranslation";

import ExteriorImageSlide from "./ExteriorImageSlide";

const ExteriorSection: FC = () => {
  const { t } = useTranslation();

  return (
    <div css={sectionWrapperStyle}>
      <div css={sectionTextAreaStyle}>
        <Typography variant="subtitle_1">{t("외판 손상")}</Typography>
        <div>
          <Typography variant="body_1">
            {t("광택 시공에서 없어지지 않는 도색 손상, 찌그러짐은 흔한 감가 요소예요.")}
          </Typography>
          <Typography variant="body_1" color="brand_2">
            {t("손상 부위가 여러개일 경우, 손상 갯수가 아닌 '판 단위'로 계산해요.")}
          </Typography>
        </div>
      </div>
      <ExteriorImageSlide />
      <div>
        <div css={tableRowStyle}>
          <Typography variant="subtitle_3">{t("복원 필요한 문콕")}</Typography>
          <Typography variant="body_2">{t("3 ~ 5만원 /개")}</Typography>
        </div>
        <Divider />
        <div css={tableRowStyle}>
          <Typography variant="subtitle_3">{t("판금도색 (국산차)")}</Typography>
          <Typography variant="body_2">{t("10 ~ 13만원 /판")}</Typography>
        </div>
        <Divider />
        <div css={tableRowStyle}>
          <Typography variant="subtitle_3">{t("판금도색 (수입차)")}</Typography>
          <Typography variant="body_2">{t("15 ~ 18만원 /판")}</Typography>
        </div>
      </div>
      <Typography variant="caption_1" color="white_7">
        <ul>
          <li>{t("앞 범퍼와 앞 휀다는 서로 다른 외판입니다.")}</li>
          <li>{t("필러 패널, 사이드실 패널, 사이드 미러도 각각 외판 1판에 해당합니다.")}</li>
        </ul>
      </Typography>
    </div>
  );
};

export default ExteriorSection;
