import React from "react";

import { Route } from "react-router";

import Inspector from "./Inspector/Inspector";
import PreInspection from "./PreInspection/PreInspection";

const SurveyPage: React.FC = () => {
  return (
    <>
      <Route exact path="/cars/:hashId/survey/inspector" component={Inspector} />
      <Route exact path="/cars/:hashId/survey/pre_inspection" component={PreInspection} />
    </>
  );
};

export default SurveyPage;
