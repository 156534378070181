/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";

import { css } from "@emotion/react";
import { Typography, colors, effects } from "@PRNDcompany/heydealer-ui";
import { Swiper } from "swiper";

import reviewImg1 from "./assets/reviewImage1.png";
import reviewImg2 from "./assets/reviewImage2.png";
import reviewImg3 from "./assets/reviewImage3.png";
import reviewLogo from "./assets/reviewLogo.png";

import "swiper/swiper.scss";

const reviewCards = [
  {
    image: reviewImg1,
    description: "헤이딜러 전문평가사가 올린 정보로 경매가 진행된다.\n사전에 검수했기 때문에 절대 추가 네고는 없다.",
    car: "G70 2.0T",
    url: "https://blog.naver.com/sakl25/222471241795",
    logo: reviewLogo,
  },
  {
    image: reviewImg2,
    description:
      "헤이딜러 ZERO 장점은 딜러를 직접 만날 필요가 없는 점!\n경매 최고가를 보고 탁송으로 팔기만 누르면 됩니다.",
    car: "로체 이노베이션 LEX20",
    url: "https://blog.naver.com/refrain/222505162734",
    logo: reviewLogo,
  },
  {
    image: reviewImg3,
    description:
      "너무나 친절한 헤이딜러 평가사가 자세히 설명해주고, 경매에 올려준다. 더 궁금한 점은 헤이딜러 채팅에 물어보면 된다.",
    car: "말리부  2.0 LT",
    url: "https://blog.naver.com/solhee857/222498217608",
    logo: reviewLogo,
  },
];

const ReviewCarousel: React.FC = () => {
  useEffect(() => {
    new Swiper(".swiper-container", {
      spaceBetween: 12,
    });
  }, []);

  const cardStyle = css`
    box-sizing: border-box;
    height: 90vw;

    padding: 1rem;

    border: 1px solid ${colors.white_4};
    border-radius: 0.25rem;

    background: ${colors.white_1};
    ${effects.layer.level_4};

    z-index: 1;
    max-height: 36rem;
  `;

  const cardContentStyle = css`
    display: flex;
    flex-direction: column;

    > *:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  `;

  return (
    <div css={{ paddingRight: "22%" }}>
      <div className="swiper-container" css={{ overflow: "visible !important" }}>
        <div className="swiper-wrapper">
          {reviewCards.map((card, index) => (
            <div
              css={cardStyle}
              className="swiper-slide"
              key={index}
              onClick={() => {
                window.open(card.url);
              }}
            >
              <div css={cardContentStyle}>
                <img css={{ width: "100%" }} src={card.image} alt="차량 정검" />
                <Typography variant="body_2">{card.description}</Typography>
                <Typography variant="caption_1" color="white_7">
                  {card.car}
                </Typography>
              </div>
              <img
                css={{ width: "3.75rem", height: "1.25rem", position: "absolute", bottom: "1rem", right: "1rem" }}
                src={card.logo}
                alt="naver"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReviewCarousel;
