import React from "react";

import ProcessImage1 from "../../../images/process1.jpg";
import ProcessImage2 from "../../../images/process2.jpg";
import ProcessImage3 from "../../../images/process3.jpg";
import ProcessImage4 from "../../../images/process4.jpg";
import ProcessImage5 from "../../../images/process5.jpg";

import Process from "./Process/Process";

import styles from "./TradeProcess.module.scss";

const processes = [
  {
    title: "무료 픽업",
    desc: "예약한 시간에 탁송보험 가입된 기사가 방문합니다.",
    image: ProcessImage1,
  },
  {
    title: "진단센터 입고",
    desc: "헤이딜러 진단센터로 안전하게 탁송됩니다.",
    image: ProcessImage2,
  },
  {
    title: "헤이딜러 진단",
    desc: "투명하게 진단하고 확정가격을 안내드립니다.",
    image: ProcessImage3,
  },
  {
    title: "판매결정",
    desc: "가격이 변동되면 판매여부만 다시 결정하세요.",
    info: "* 즉시 재경매 또는 돌려받기도 가능 (무료)",
    image: ProcessImage4,
  },
  {
    title: "이전 & 즉시 송금",
    desc: "명의이전과 송금 에스크로까지 헤이딜러가 신속히 완료합니다.",
    image: ProcessImage5,
  },
];

const TradeProcess: React.FC = () => {
  return (
    <div className={styles.tradeProcess}>
      {processes.map((process) => (
        <Process
          key={process.title}
          title={process.title}
          desc={process.desc}
          image={process.image}
          info={process.info}
        />
      ))}
    </div>
  );
};

export default TradeProcess;
