/** @jsxImportSource @emotion/react */

import type { FC, MouseEventHandler } from "react";
import { useState } from "react";

import { css } from "@emotion/react";
import { colors, Typography } from "@PRNDcompany/heydealer-ui";

import exterior1Img from "../../assets/img/exterior_1.png";
import exterior2Img from "../../assets/img/exterior_2.png";
import exterior3Img from "../../assets/img/exterior_3.png";
import exterior4Img from "../../assets/img/exterior_4.png";
import { useTranslation } from "../../useTranslation";

const imageSlideStyle = css`
  display: grid;
  justify-content: start;
  gap: 0.5rem;

  margin: 0 -1.5rem;

  padding: 0 1.5rem;

  color: ${colors.black_5};

  grid-auto-flow: column;
  overflow-x: auto;

  scroll-snap-type: x mandatory;

  cursor: grab;

  ::-webkit-scrollbar {
    display: none;
  }

  ::after {
    width: 1rem;

    content: "";
  }
`;

const imageSlideItemStyle = css`
  display: flex;
  justify-content: center;

  box-sizing: border-box;
  width: 100%;

  padding-top: calc((12 / 220) * 100%);

  border-radius: 0.25rem;

  text-align: center;

  aspect-ratio: 19.5 / 13.75;

  scroll-snap-align: center;
  scroll-snap-stop: always;

  background-size: cover;
`;

const ExteriorImageSlide: FC = () => {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const { t } = useTranslation();

  const onMouseMove: MouseEventHandler = (e) => {
    if (isMouseDown) {
      e.currentTarget.scrollLeft -= e.movementX;
    }
  };

  return (
    <div
      css={[
        imageSlideStyle,
        { gridTemplateColumns: "repeat(4, 100%)" },
        isMouseDown && { cursor: "grabbing", scrollSnapType: "none" },
      ]}
      onMouseDown={() => setIsMouseDown(true)}
      onMouseMove={onMouseMove}
      onMouseUp={() => setIsMouseDown(false)}
      onMouseLeave={() => setIsMouseDown(false)}
    >
      <div css={[imageSlideItemStyle, { backgroundImage: `url("${exterior1Img}")` }]}>
        <Typography variant="body_2">{t("승용차 기준 약 13판의 외판이 있어요.")}</Typography>
      </div>
      <div css={[imageSlideItemStyle, { backgroundImage: `url("${exterior2Img}")` }]}>
        <Typography variant="body_2">{t("스크래치는 3개여도, 1판 수리비가 발생해요.")}</Typography>
      </div>
      <div css={[imageSlideItemStyle, { backgroundImage: `url("${exterior3Img}")` }]}>
        <Typography variant="body_2">{t("스크래치는 1개지만, 2판 수리비가 발생해요.")}</Typography>
      </div>
      <div css={[imageSlideItemStyle, { backgroundImage: `url("${exterior4Img}")`, color: colors.white_6 }]}>
        <Typography variant="body_2">{t("사이드미러 스크래치도 1판으로 계산해요.")}</Typography>
      </div>
    </div>
  );
};

export default ExteriorImageSlide;
