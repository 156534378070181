import React from "react";

import { TextareaTextfield } from "@PRNDcompany/heydealer-ui";
import type { FormikErrors } from "formik";
import StarRating from "react-svg-star-rating";

import styles from "./SurveyStar.module.scss";

export interface SurveyProps {
  rating: number;
  content: string;
}

interface Props {
  data: SurveyProps;
  placeholder: string;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<SurveyProps>>;
}

const SurveyStar: React.FC<Props> = ({ data: { rating, content }, placeholder, setFieldValue }) => {
  return (
    <div className={styles.wrapper}>
      <StarRating
        unit="half"
        initialRating={rating / 2}
        size={40}
        containerClassName={styles.starContainer}
        starClassName={styles.star}
        hoverColor="#396eff"
        activeColor="#396eff"
        emptyColor="#E9EDF4"
        handleOnClick={(rating) => setFieldValue("rating", rating * 2)}
      />
      <TextareaTextfield
        size={56}
        height={120}
        value={content}
        placeholder={placeholder}
        onChange={(e) => setFieldValue("content", e.currentTarget.value)}
      />
    </div>
  );
};

export default SurveyStar;
