import { css } from "@emotion/react";

export const globalPageStyle = css`
  div#root {
    position: relative;

    height: auto;

    margin: 0 auto;

    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);

    min-height: 100vh;

    user-select: none;
  }
`;
