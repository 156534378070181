/** @jsxImportSource @emotion/react */

import React, { useMemo } from "react";

import { TextareaTextfield } from "@PRNDcompany/heydealer-ui";
import type { FormikErrors } from "formik";

import Layer from "../../design-system/Frame/Layer/Layer";
import Typography from "../Typography/Typography";

import Fair from "./FAIR.png";
import Good from "./GOOD.png";
import Poor from "./POOR.png";
import {
  satisfactionIconStyle,
  satisfactionListStyle,
  satisfactionSelectedStyle,
  satisfactionStyle,
  wrapperStyle,
} from "./styles";

enum SurveySatisfaction {
  GOOD = "good",
  FAIR = "fair",
  POOR = "poor",
}

export interface SurveyProps {
  satisfaction: SurveySatisfaction | null;
  content: string;
}

interface Props {
  data: SurveyProps;
  placeholder: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<SurveyProps>>;
}

const Survey: React.FC<Props> = ({ data: { satisfaction, content }, placeholder, setFieldValue }) => {
  const options = useMemo(() => {
    return [
      {
        icon: Good,
        text: "만족",
        satisfaction: SurveySatisfaction.GOOD,
      },
      {
        icon: Fair,
        text: "보통",
        satisfaction: SurveySatisfaction.FAIR,
      },
      {
        icon: Poor,
        text: "불만족",
        satisfaction: SurveySatisfaction.POOR,
      },
    ];
  }, []);

  const onClickSatisfaction = (targetSatisfaction: SurveySatisfaction) => {
    const isCancelClick = satisfaction === targetSatisfaction;
    isCancelClick ? setFieldValue("satisfaction", null) : setFieldValue("satisfaction", targetSatisfaction);
  };

  return (
    <div css={wrapperStyle}>
      <div css={satisfactionListStyle}>
        {options.map((item, i) => (
          <div key={i} onClick={() => onClickSatisfaction(item.satisfaction)}>
            <Layer
              variant={"level_2"}
              css={[satisfactionStyle, item.satisfaction === satisfaction && satisfactionSelectedStyle]}
            >
              <img css={satisfactionIconStyle} src={item.icon} alt={item.text} />
              <Typography variant={"Body_16"}>{item.text}</Typography>
            </Layer>
          </div>
        ))}
      </div>
      <TextareaTextfield
        size={56}
        value={content}
        placeholder={placeholder}
        onChange={(e) => setFieldValue("content", e.currentTarget.value)}
        height={120}
      />
    </div>
  );
};

export default Survey;
