/** @jsxImportSource @emotion/react */
import type { VFC } from "react";
import { Fragment } from "react";

import { css } from "@emotion/react";
import { Typography } from "@PRNDcompany/heydealer-ui";
import { range } from "lodash";

type ProgressProps = {
  title: string;
  items: ProgressItem[];
};

type ProgressItem = {
  title: string;
  desc: string;
};

const itemListStyle = css`
  > *:not(:last-child) {
    margin-bottom: 1.25rem;
  }
`;

const itemStyle = css`
  display: grid;
  align-items: center;
  row-gap: 0.25rem;

  grid-template-columns: 1.5rem 1fr;
`;

const smallDotContainerStyle = css`
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 1.125rem;

  > *:not(:last-child) {
    margin-bottom: 0.375rem;
  }
`;

const SmallDots = ({ count }: { count: number }) => (
  <div css={smallDotContainerStyle}>
    {range(count).map((key) => (
      <svg width="2" height="2" viewBox="0 0 2 2" key={key}>
        <circle cx="1" cy="1" r="1" fill="#CCD8E8" />
      </svg>
    ))}
  </div>
);

const Progress: VFC<ProgressProps> = ({ title, items }) => {
  return (
    <Fragment>
      <Typography variant="subtitle_2" color="brand_2" css={{ marginBottom: "1.375rem" }}>
        {title}
      </Typography>
      <div css={itemListStyle}>
        {items.map(({ title, desc }, index) => (
          <Fragment key={title}>
            <div css={itemStyle}>
              <div css={{ display: "flex", justifyContent: "center", position: "relative" }}>
                <svg width="8" height="8" viewBox="0 0 8 8">
                  <circle cx="4" cy="4" r="4" fill="#396EFF" />
                </svg>
                {items.length - 1 > index && <SmallDots count={6} />}
              </div>
              <Typography variant="subtitle_2">{title}</Typography>
              <Typography variant="body_2" css={{ gridArea: "2/2" }} color="black_6">
                {desc}
              </Typography>
            </div>
          </Fragment>
        ))}
      </div>
    </Fragment>
  );
};

export default Progress;
