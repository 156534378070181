/** @jsxImportSource @emotion/react */

import type { FC } from "react";

import { Global } from "@emotion/react";
import { close } from "@PRNDcompany/heydealer-lib";
import {
  DeprecatedAppBar as AppBar,
  colors,
  Divider,
  StrokeCloseIcon,
  TitleBar,
  Typography,
  typographyStyles,
} from "@PRNDcompany/heydealer-ui";

import PageWrapper from "../../components/PageWrapper";
import { globalPageStyle } from "../../styles";

import { ReactComponent as PictoAlert } from "./assets/img/picto_alert.svg";
import { ReactComponent as PictoAllDocuImg } from "./assets/img/picto_alldocu.svg";
import { ReactComponent as PictoTaxImg } from "./assets/img/picto_tax.svg";
import InfoItem from "./components/InfoItem";
import { contentAreaStyle, listStyle, olStyle } from "./style";

const CarTaxRefundPage: FC = () => (
  <PageWrapper title="자동차세 돌려받기">
    <Global styles={globalPageStyle} />
    <AppBar leftIcon={{ icon: StrokeCloseIcon, onClick: close }} sticky />
    <TitleBar
      title="자동차세 돌려받기"
      description={"1년치 자동차세를 한번에 납부했었다면, \n남은 기간만큼 돌려받을 수 있어요."}
    />
    <Divider />
    <div css={contentAreaStyle}>
      <InfoItem Icon={PictoTaxImg}>
        <div>
          <Typography variant="subtitle_2">환급 금액</Typography>
          <Typography variant="body_1">사용 일수를 제외한 나머지 기간 일할 계산</Typography>
        </div>
      </InfoItem>
      <InfoItem Icon={PictoAllDocuImg}>
        <div>
          <Typography variant="subtitle_2">환급 방법</Typography>
          <ol css={[listStyle, olStyle, typographyStyles.body_1]}>
            별도의 신청 필요없이 판매 후 한달 내에
            <li>
              자동차 등록지 주소로 <br />
              환급 안내 통지서 우편 발송
            </li>
            <li>
              자동차세 선납 당시 등록한 계좌로 <br />
              환급금 입금
            </li>
          </ol>
        </div>
      </InfoItem>
      <InfoItem Icon={PictoAlert}>
        <div css={{ color: colors.critical_3 }}>
          <Typography variant="subtitle_2">유의해주세요.</Typography>
          <Typography variant="body_1">
            우편물을 받기 힘들거나 더 빠르게 환급받고 싶다면, 별도로 신청해주세요.
          </Typography>
        </div>
        <ol css={[listStyle, olStyle, typographyStyles.body_2]}>
          [전화 신청] <br />
          <li>시청이나 관할구청 세무과에 전화</li>
          <li>차주이름, 차량번호, 본인 계좌번호 확인</li>
          <li>담당자 확인 후 본인 계좌로 입금</li>
        </ol>
        <ol css={[listStyle, olStyle, typographyStyles.body_2]}>
          [온라인 신청] <br />
          <li>위택스 홈페이지 또는 앱 접속</li>
          <li>공인인증서 로그인</li>
          <li>[환급신청] &gt; [지방세] 에서 환급액 조회</li>
          <li>환급 계좌 정보 입력</li>
        </ol>
      </InfoItem>
    </div>
  </PageWrapper>
);

export default CarTaxRefundPage;
