import React from "react";

import Typography from "../../../components/Typography/Typography";
import img1 from "../assets/picpfdp_body_1.png";
import img2 from "../assets/picpfdp_body_2.png";
import img3 from "../assets/picpfdp_body_3.png";
import img4 from "../assets/picpfdp_body_4.png";
import IndexUpEmoji from "../components/IndexUpEmoji";

import 진단오류보상기준버튼 from "./진단오류보상기준버튼";

import styles from "./BodySection.module.scss";

interface Props {}

const BodySection: React.FC<Props> = () => {
  return (
    <div className={styles.bodySectionWrapper}>
      <section className={styles.singleSection}>
        <Typography className={styles.sectionTitle} variant={"subtitle"}>
          <p>1. 견적 재확인</p>
        </Typography>
        <img className={styles.sectionImage} src={img1} alt={"견적 재확인"} />
        <Typography className={styles.sectionContentText} variant={"bodyMulti"}>
          <p>일반 차량과 동일합니다. 견적을 재확인해주세요.</p>
          <p>입찰 전 안내드린대로 진단완료 차량은 견적실수 처리가 엄격합니다.</p>
          <p style={{ display: "flex" }}>
            <IndexUpEmoji />
            <p>
              견적실수 시, <b className={styles.yellow}>1주일 견적정지</b>와 <b className={styles.yellow}>경고 1회</b>가
              부여됩니다.
            </p>
          </p>
          <p style={{ display: "flex" }}>
            <IndexUpEmoji />
            <p>
              연결 후 견적실수 시, <b className={styles.yellow}>1달 견적정지</b>와{" "}
              <b className={styles.yellow}>경고 1회</b>가 부여됩니다.
            </p>
          </p>
          <p style={{ display: "flex" }}>
            <IndexUpEmoji />
            <p>
              견적실수가 반복되면, <b className={styles.yellow}>진단완료 차량 입찰이 불가</b>하도록 조치될 수 있습니다.
            </p>
          </p>
        </Typography>
      </section>
      <section className={styles.singleSection}>
        <Typography className={styles.sectionTitle} variant={"subtitle"}>
          <p>2. 인수정보 입력</p>
        </Typography>
        <img className={styles.sectionImage} src={img2} alt={"매수자 인적사항 및 탁송정보 입력"} />
        <Typography className={styles.sectionContentText} variant={"bodyMulti"}>
          <p>탁송 도착지와 매수자 인적사항을 입력해주세요.</p>
          <div style={{ display: "flex" }}>
            <IndexUpEmoji />
            <p>
              헤이딜러가 압류/저당, 사업자 유무, 비사업용 여부 등을 확인하여, 이전 필요서류를 차와 함께 보내드립니다.
            </p>
          </div>
          <br />
          <p>탁송은 평균 2-3일, 최대 5일 안으로 정해집니다.</p>
          <p>최대한 빠른 날짜로 고객과 조율한 뒤 안내드립니다.</p>
          <p>
            <b>당일 거래가 진행될 수도 있습니다. (빠른 판매원할 경우, 5시간 뒤로 탁송 예약가능)</b>
          </p>
          <br />
          <p>출발-도착지 거리에 따른 표준 탁송비가 정해져 있습니다.</p>
          <p>탁송은 헤이딜러 제휴업체에서 수행합니다.</p>
          <br />
          <p>탁송이 잘 안잡히는 상황에는 배정지연비가 추가될 수 있습니다.</p>
          <p>(ex. 우천시, 연휴 직전 등)</p>
          <br />
          <p>배정지연비가 발생할 경우, 헤이딜러와 매수자가 8:2로 부담합니다.</p>
          <br />
          <p>기름이 부족한 경우, 네이버 지도 예상 유류비보다 조금</p>
          <p>여유있게 주유됩니다.(주유비는 탁송비와 함께 청구)</p>
        </Typography>
      </section>
      <section className={styles.singleSection}>
        <Typography className={styles.sectionTitle} variant={"subtitle"}>
          <p>3. 차대금 입금</p>
        </Typography>
        <img className={styles.sectionImage} src={img3} alt={"차대금 입금"} />
        <Typography className={styles.sectionContentText} variant={"bodyMulti"}>
          <p>탁송 2시간 전까지 헤이딜러 에스크로 계좌로 차대금을 입금해주세요.</p>
          <br />
          <p>탁송출발 시, 차대금이 안전하게 차주 계좌로 송금되며</p>
          <p>헤이딜러 → 차주 계좌 송금 입금증이 발급됩니다.</p>
          <br />
          <div style={{ display: "flex" }}>
            <IndexUpEmoji />
            <p>
              헤이딜러 에스크로 계좌 입금은 세무증빙이 가능하며, FM으로 세무처리하는 대형 기업형 업체도 이용하고 있습
              니다. 탁송비를 포함한 모든 부대비용 또한 계산서가 발행 됩니다.
            </p>
          </div>
        </Typography>
      </section>
      <section className={styles.singleSection}>
        <Typography className={styles.sectionTitle} variant={"subtitle"}>
          <p>4. 거래 마무리</p>
        </Typography>
        <img className={styles.sectionImage} src={img4} alt={"거래 마무리"} />
        <Typography className={styles.sectionContentText} variant={"bodyMulti"}>
          <p>이전완료 후 이전등록증, 성능기록부를 업로드 해주세요.</p>
          <p>중대한 진단오류가 있다면, 인수 후 2일 내 클레임이 가능합니다.</p>
        </Typography>
        <진단오류보상기준버튼 />
      </section>
    </div>
  );
};

export default BodySection;
