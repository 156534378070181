/** @jsxImportSource @emotion/react */

import type { FC } from "react";

import { Typography } from "@PRNDcompany/heydealer-ui";

import questionIcon from "../assets/img/questionIcon.png";

type QuestionItemType = {
  title: string;
  contentParagraphs: string[];
};

const QuestionItem: FC<QuestionItemType> = ({ title, contentParagraphs }) => {
  return (
    <>
      <div
        css={{
          display: "grid",
          gridAutoFlow: "column",
          padding: "0.5rem 0",
          gap: "0.5rem",
          gridTemplateColumns: "auto 1fr",
        }}
      >
        <img src={questionIcon} css={{ width: "0.875rem", height: "0.875rem", marginTop: "0.5rem" }} />
        <Typography variant="subtitle_2">{title}</Typography>
      </div>
      <div css={{ padding: "0 1.5rem", display: "grid", gap: "0.75rem" }}>
        {contentParagraphs.map((paragraph, index) => (
          <Typography key={index} variant="body_1">
            {paragraph}
          </Typography>
        ))}
      </div>
    </>
  );
};

export default QuestionItem;
