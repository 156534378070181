import React from "react";

import Typography from "../../Typography/Typography";

import noItem from "./noitem.png";

import styles from "./NoItem.module.scss";

interface Props {
  text: string;
}

const NoItem: React.FC<Props> = ({ text }) => {
  return (
    <div className={styles.noItemWrapper}>
      <div>
        <img className={styles.noItemImage} src={noItem} />
      </div>
      <Typography className={styles.noItemText} variant={"body_m"}>
        {text}
      </Typography>
    </div>
  );
};

export default NoItem;
