import { css } from "@emotion/react";

export const contentAreaStyle = css`
  display: grid;
  gap: 1.5rem;

  padding: 2rem 1.5rem;
`;

export const listStyle = css`
  li {
    margin-left: 1rem;
  }
`;

export const olStyle = css`
  li {
    list-style: decimal outside;
  }
`;

export const ulStyle = css`
  li {
    list-style: "• " outside;
  }
`;
