/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";

import { css } from "@emotion/react";
import { Button, SinglelineTextfield } from "@PRNDcompany/heydealer-ui";
import { useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { get, omitBy } from "lodash";
import Div100vh from "react-div-100vh";
import { useParams } from "react-router";

import pxios from "../../../apis/pxios";
import Emoji from "../../../components/Emoji/Emoji";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import type { SurveyProps } from "../../../components/SurveyStar/SurveyStar";
import SurveyStar from "../../../components/SurveyStar/SurveyStar";
import Typography from "../../../components/Typography/Typography";
import { setCarToken } from "../../../cores/setCarToken";
import Radiobox from "../../../design-system/Form/Selector/Radiobox/Radiobox";
import Box from "../../../design-system/Frame/Box/Box";
import Layer from "../../../design-system/Frame/Layer/Layer";
import AllDone from "../AllDone/AllDone";

import styles from "./PreInspection.module.scss";

enum NotSellReason {
  priceCheckPurpose = "price_check_purpose",
  lowPrice = "low_price",
  otherService = "other_service",
  delayedOrMissingValidityPeriod = "delayed_or_missing_validity_period",
}

const NOT_SELL_REASONS: NotSellReason[] = [
  NotSellReason.priceCheckPurpose,
  NotSellReason.lowPrice,
  NotSellReason.otherService,
  NotSellReason.delayedOrMissingValidityPeriod,
];

const NOT_SELL_REASON_TO_LABEL: { [key in NotSellReason]: string } = {
  [NotSellReason.priceCheckPurpose]: "판매가만 궁금하여 신청",
  [NotSellReason.lowPrice]: "판매가 불만족",
  [NotSellReason.otherService]: "다른 서비스 선택",
  [NotSellReason.delayedOrMissingValidityPeriod]: "판매연기 또는 유효기간 놓침",
};

interface Reason {
  notSellReason: NotSellReason | null;
  notSellDescription: string;
}

const PreInspection: React.FC = () => {
  const { hashId } = useParams<{ hashId: string }>();

  const { isLoading, refetch, data } = useQuery({
    queryKey: ["pre_inspection_review", hashId],
    queryFn: async () => {
      const result = await pxios.get<{ is_not_sell_reason_required: boolean; is_completed: boolean }>(
        `/v2/customers/web/cars/${hashId}/pre_inspection_review/`,
        setCarToken()
      );

      return result.data;
    },
  });

  const isCompleted = get(data, "is_completed");

  const { values, setFieldValue, handleSubmit } = useFormik<SurveyProps & Reason>({
    initialValues: {
      rating: 0,
      content: "",
      notSellReason: null,
      notSellDescription: "",
    },
    onSubmit: async (values) => {
      if (values.rating === 0 || (data?.is_not_sell_reason_required && values.notSellReason === null)) {
        return;
      }

      const isNotSellReasonRequired = get(data, "is_not_sell_reason_required");

      const requestData = isNotSellReasonRequired
        ? omitBy(
            {
              rating: values.rating,
              content: values.content,
              not_sell_reason: values.notSellReason,
              not_sell_description: values.notSellDescription,
            },
            (value) => value === ""
          )
        : {
            rating: values.rating,
            content: values.content,
          };

      await pxios.post(`/v2/customers/web/cars/${hashId}/pre_inspection_review/`, requestData, setCarToken());

      await refetch();
    },
  });

  useEffect(() => {
    if (values.notSellReason !== null) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [values.notSellReason]);

  if (isLoading) {
    return null;
  }

  if (isCompleted) {
    return (
      <PageWrapper title={"헤이딜러 zero 설문조사"}>
        <AllDone
          title={
            <>
              입력 완료! <br />
              소중한 의견 감사합니다
            </>
          }
          description={<>서비스 개선에 꼭 참고하겠습니다.</>}
        />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper title={"헤이딜러 zero 설문조사"}>
      <Div100vh>
        <form onSubmit={handleSubmit} className={styles.wrapper}>
          <div>
            <Layer variant={"level_0"} className={styles.titleWrapper}>
              <Typography className={styles.title} variant={"Headline_24"}>
                헤이딜러 zero는
                <br />
                어떠셨나요? <Emoji label={"smile"} symbol={"🙂"} />
                <Emoji label={"pray"} symbol={"🙏"} />
              </Typography>
              <Typography className={styles.content} variant={"Body_16"}>
                소중한 의견을 남겨주시면,
                <br />
                서비스 개선에 꼭 참고할게요.
              </Typography>
            </Layer>
            <SurveyStar
              data={values}
              placeholder={"개선이 필요한 점 또는 바라는 점을 적어주세요.(선택입력)"}
              setFieldValue={setFieldValue}
            />
            {data?.is_not_sell_reason_required && (
              <div className={styles.reasonWrapper}>
                <Layer variant={"level_0"} className={styles.layer}>
                  <Typography className={styles.title} variant={"Headline_24"}>
                    판매 요청하지 않으신 이유가 무엇인가요?
                    <Emoji label={"sad"} symbol={"😟"} />
                  </Typography>
                </Layer>
                <div className={styles.radioWrapper}>
                  {NOT_SELL_REASONS.map((item, i) => {
                    const isChecked = values.notSellReason === item;

                    return (
                      <Box variant={isChecked ? "blue" : "white"} key={i} className={styles.selectedBox}>
                        <Radiobox
                          size="big"
                          variant={"list"}
                          checked={isChecked}
                          name={item}
                          label={NOT_SELL_REASON_TO_LABEL[item]}
                          onChange={() => {
                            setFieldValue("notSellReason", item);
                          }}
                        />
                        {(item === NotSellReason.lowPrice || item === NotSellReason.otherService) && isChecked && (
                          <div className={styles.notSellDescription}>
                            <Typography variant={"Subtitle_16"} className={styles.subTitle}>
                              다른 곳에서 견적을 받으셨나요?
                            </Typography>
                            <Typography variant={"Caption"} className={styles.notSellDescriptionCaption}>
                              꼭 입력하지 않아도 돼요!
                            </Typography>
                            <SinglelineTextfield
                              inputMode="numeric"
                              formatType="numeric"
                              allowNegative={false}
                              decimalScale={0}
                              size={56}
                              value={values.notSellDescription}
                              onValueChange={({ floatValue }) => {
                                setFieldValue("notSellDescription", floatValue);
                              }}
                              placeholder="0"
                              unit="만원"
                            />
                          </div>
                        )}
                      </Box>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div
            css={css`
              position: sticky;
              top: 100%;

              box-sizing: border-box;

              padding: 1rem;
              padding-bottom: calc(1rem + env(safe-area-inset-bottom));
            `}
          >
            <Button
              type="submit"
              size={56}
              variant="primary"
              disabled={values.rating === 0 || (data?.is_not_sell_reason_required && values.notSellReason === null)}
              fullWidth
            >
              입력완료
            </Button>
          </div>
        </form>
      </Div100vh>
    </PageWrapper>
  );
};

export default PreInspection;
