import type { PropsWithChildren } from "react";
import React from "react";

import classNames from "classnames";

import { ReactComponent as CautionIcon } from "../../../images/mortgageAndAttachment/info_caution.svg";
import { ReactComponent as ErrorIcon } from "../../../images/mortgageAndAttachment/info_error.svg";

import styles from "./Information.module.scss";

type Variant = "warning" | "critical" | "error";
type Props = PropsWithChildren<{
  variant: Variant;
  title: string;
}>;

const getIcon = (variant: Variant) => {
  switch (variant) {
    case "critical":
    case "warning":
      return CautionIcon;
    case "error":
      return ErrorIcon;
    default:
      return null;
  }
};

const Information: React.FC<Props> = ({ variant, title, children }) => {
  const Icon = getIcon(variant);

  return (
    <div className={classNames(styles.container, styles[variant])}>
      <header className={styles.header}>
        {Icon && <Icon className={styles.icon} />}
        <div className={styles.title}>{title}</div>
      </header>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Information;
