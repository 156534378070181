/** @jsxImportSource @emotion/react */
import type { FC, PropsWithChildren } from "react";

import { Global } from "@emotion/react";
import type { ColorToken } from "@PRNDcompany/heydealer-ui";
import { colors } from "@PRNDcompany/heydealer-ui";
import { Helmet } from "react-helmet";

const getIsColorToken = (color: string): color is ColorToken => Object.keys(colors).includes(color);

type PageProps = PropsWithChildren<{
  background?: string;
  title?: string;
}>;

const Page: FC<PageProps> = ({ background: _background, children, title }) => {
  const background = _background ? (getIsColorToken(_background) ? colors[_background] : _background) : colors.white_1;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Global
        styles={[
          {
            body: { WebkitTapHighlightColor: "transparent", WebkitTouchCallout: "none" },
            "*": { WebkitUserDrag: "none!important" },
          },
        ]}
      />
      {/* Workaround; Cover overscroll area in iOS */}
      <div
        css={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background,
          zIndex: -1,
        }}
      />

      {children}
    </>
  );
};

export default Page;
