/** @jsxImportSource @emotion/react */

import type { FC } from "react";

import { colors, Typography } from "@PRNDcompany/heydealer-ui";

import { ReactComponent as EtcShieldBlue } from "../assets/ic/etc_shield_blue.svg";
import type { ReductionReviewManager } from "../types";

type ReductionReviewType = {
  reductionReviewManager: ReductionReviewManager;
};

const ReductionReview: FC<ReductionReviewType> = ({ reductionReviewManager }) => {
  return (
    <div css={{ background: colors.white_1 }}>
      <div
        css={{
          padding: "1.25rem 1.5rem",
          display: "grid",
          gridAutoFlow: "column",
          alignItems: "center",
          gap: "0.563rem",
          gridTemplateColumns: "auto 1fr",
        }}
      >
        <Typography variant="headline_3">검토 과정</Typography>
        <EtcShieldBlue />
      </div>
      <div css={{ padding: "0 1.5rem" }}>
        <div
          css={{
            background: colors.white_3,
            border: `1px solid ${colors.white_4}`,
            borderRadius: "0.25rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div css={{ padding: "1rem", display: "grid", gap: "0.5rem" }}>
            <Typography variant="body_1">
              <Typography variant="subtitle_2" inline>
                감가심사센터
              </Typography>
              에서 부당감가를 검토·보상해드립니다.
            </Typography>
            <Typography variant="caption_1" color="white_7">
              <b>담당 매니저</b>
              {` ${reductionReviewManager.name.replace("매니저", "")}`}
            </Typography>
          </div>
          <img src={reductionReviewManager.background_image_url} css={{ height: "7.25rem" }} />
        </div>
      </div>
    </div>
  );
};

export default ReductionReview;
