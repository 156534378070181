import { logEvent } from "@PRNDcompany/heydealer-lib";

import type { Car } from "../../types";

export type LogClickRegisterFixedPriceZeroEventParams = {
  car_hash_id: Car["hash_id"];
  car_status: Car["status"];
  desired_price: number;
  result: "success" | "desired_price_too_low" | "desired_price_too_high";
  is_desired_price_suggestion_selected?: boolean;
};

export const logClickRegisterFixedPriceZeroEvent = (params: LogClickRegisterFixedPriceZeroEventParams) => {
  logEvent({
    event_name: "click_register_fixed_price_zero",
    event_description: "(희망가 zero) 희망가 신청",
    parameters: {
      car_hash_id: params.car_hash_id,
      car_status: params.car_status,
      desired_price: params.desired_price.toString(),
      result: params.result,
      ...(params.is_desired_price_suggestion_selected?.toString()
        ? { is_desired_price_suggestion_selected: params.is_desired_price_suggestion_selected.toString() }
        : {}),
    },
    provider: { api: true, firebase: true, appsflyer: false, facebook: false },
  });
};
