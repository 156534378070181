/**
 * 인증로직
 * 노출된 hashId로 차량을 조회하지 못하게 하기위해서 별도의 token을 서버에서 발급 받습니다.
 *
 * 1. 차량 생성시 만들어진 token은 권한이 필요한 모든 페이지에서 get 파라미터로 가지고 있습니다.
 * 2. token을 API 호출 시 [Authoriztion: CAR-TOKEN  xxxx] 형식으로 header에 담아 전달한다.
 * **/

export const setCarToken = (token?: string) => {
  const carToken = sessionStorage.getItem("CAR-TOKEN");
  if (token || carToken) {
    return {
      headers: {
        Authorization: `CAR-TOKEN ${carToken}`,
      },
    };
  }
};
