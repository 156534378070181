import React, { useState } from "react";

import { map } from "lodash";

import Typography from "../../../components/Typography/Typography";

import FAQ from "./FAQ/FAQ";
import faqs from "./FAQ/faqs";

import styles from "./FAQSection.module.scss";

interface Props {}

const FAQSection: React.FC<Props> = () => {
  const [opened, setOpened] = useState<number | null>(null);

  const genToggleFAQ = (index: number) => {
    return () => {
      opened === index ? setOpened(null) : setOpened(index);
    };
  };

  return (
    <div className={styles.FAQSectionWrapper}>
      <Typography variant={"subtitle"} className={styles.title}>
        자주 묻는 질문
      </Typography>
      {map(faqs, (faq, index) => (
        <FAQ isOpened={index === opened} toggleFAQ={genToggleFAQ(index)} title={faq.title} content={faq.content} />
      ))}
    </div>
  );
};

export default FAQSection;
