/** @jsxImportSource @emotion/react */
import React from "react";

import { Button } from "@PRNDcompany/heydealer-ui";
import { useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { get } from "lodash";
import Div100vh from "react-div-100vh";
import { useParams } from "react-router";

import pxios from "../../../apis/pxios";
import Emoji from "../../../components/Emoji/Emoji";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import type { SurveyProps } from "../../../components/Survey/Survey";
import Survey from "../../../components/Survey/Survey";
import Typography from "../../../components/Typography/Typography";
import { setCarToken } from "../../../cores/setCarToken";
import Layer from "../../../design-system/Frame/Layer/Layer";
import AllDone from "../AllDone/AllDone";

import styles from "./Inspector.module.scss";

const Inspector: React.FC = () => {
  const { hashId } = useParams<{ hashId: string }>();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["inspector_review", hashId],
    queryFn: () =>
      pxios.get<{ inspector_name: string; is_completed: boolean }>(
        `/v2/customers/web/cars/${hashId}/inspector_review/`,
        setCarToken()
      ),
  });

  const inspectorName = get(data, ["data", "inspector_name"]);
  const isCompleted = get(data, ["data", "is_completed"]);
  const { values, setFieldValue, handleSubmit } = useFormik<SurveyProps>({
    initialValues: {
      satisfaction: null,
      content: "",
    },
    onSubmit: async (values) => {
      if (!values.satisfaction) {
        return;
      }

      await pxios.post(`/v2/customers/web/cars/${hashId}/inspector_review/`, values, setCarToken());

      await refetch();
    },
  });

  if (isLoading) {
    return null;
  }

  if (isCompleted) {
    return (
      <PageWrapper title={"평가사 설문조사"}>
        <AllDone
          title={
            <>
              입력 완료! <br />
              소중한 의견 감사합니다
            </>
          }
          description={<>서비스 개선에 꼭 참고하겠습니다.</>}
        />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper title={"평가사 설문조사"}>
      <Div100vh>
        <form onSubmit={handleSubmit} className={styles.wrapper}>
          <div>
            <Layer variant={"level_0"} className={styles.titleWrapper}>
              <Typography className={styles.title} variant={"Headline_24"}>
                {inspectorName} 평가사는 <br />
                어떠셨나요? <Emoji label={"ear"} symbol={"👂"} />
              </Typography>
              <Typography className={styles.content} variant={"Body_16"}>
                남겨주신 후기는 헤이딜러 본사에만 공유됩니다. 솔직한 경험을 알려주세요!
              </Typography>
            </Layer>
            <Survey
              data={values}
              placeholder={"더 자세한 이야기를 들려주세요.\n(선택입력)"}
              setFieldValue={setFieldValue}
            />
          </div>
          <div css={{ padding: "1rem" }}>
            <Button type="submit" variant="primary" size={56} disabled={values.satisfaction === null} fullWidth>
              입력완료
            </Button>
          </div>
        </form>
      </Div100vh>
    </PageWrapper>
  );
};

export default Inspector;
