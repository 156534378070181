import type { FC, PropsWithChildren } from "react";
import React, { memo } from "react";

import { css, Global } from "@emotion/react";
import type { ColorToken } from "@PRNDcompany/heydealer-ui";
import { colors } from "@PRNDcompany/heydealer-ui";
import Helmet from "react-helmet";

type Props = PropsWithChildren<{
  title: string;
  bodyColor?: ColorToken;
}>;

const PageWrapper: FC<Props> = memo(({ title, children, bodyColor = "white_1" }) => {
  return (
    <React.Fragment>
      <Global
        styles={css`
          body {
            background: ${colors[bodyColor]};
          }
        `}
      />
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </React.Fragment>
  );
});

export default PageWrapper;
