/** @jsxImportSource @emotion/react */

import type { FC } from "react";

import { colors, Divider, InfoCautionIcon, Information, Typography } from "@PRNDcompany/heydealer-ui";

import CarFrameImg from "../assets/img/car_frame.png";
import { sectionTextAreaStyle, sectionWrapperStyle, tableRowStyle } from "../styles";
import { useTranslation } from "../useTranslation";

const CarFrameSection: FC = () => {
  const { t } = useTranslation();

  return (
    <div css={sectionWrapperStyle}>
      <div css={sectionTextAreaStyle}>
        <Typography variant="subtitle_1">{t("과거 사고 이력")}</Typography>
        <div>
          <Typography variant="body_1">
            {t("과거 사고가 있었다면, 완벽히 수리를 했더라도 추후 문제가 생길 가능성 때문에 감가 요소에 해당돼요.")}
          </Typography>
        </div>
      </div>
      <img src={CarFrameImg} css={{ maxWidth: "15rem", margin: "0 auto" }} />
      <div css={{ display: "grid", gap: "1rem" }}>
        <div>
          <div css={tableRowStyle}>
            <Typography variant="subtitle_3">{t("외판 판금·교환")}</Typography>
            <Typography variant="body_2">{t("견적가의 3 ~ 4% /부위")}</Typography>
          </div>
          <Divider />
          <div css={tableRowStyle}>
            <Typography variant="subtitle_3">{t("골격 용접·판금·교환")}</Typography>
            <Typography variant="body_2">{t("견적가의 5 ~ 7% /부위")}</Typography>
          </div>
          <Divider />
          <div css={tableRowStyle}>
            <Typography variant="subtitle_3">{t("범퍼 교환")}</Typography>
            <Typography variant="body_2">{t("범퍼수리는 시세에 영향 없음")}</Typography>
          </div>
        </div>
        <Typography variant="caption_1" color="white_7">
          <ul>
            <li>{t("연식이 오래될수록 사고감가율이 낮아요.")}</li>
            <li>{t("대형차일수록 사고감가율이 큰 편이에요.")}</li>
            <li>{t("사고는 성능점검기록부, 볼트 체결 흔적 등으로 판단해요.")}</li>
          </ul>
        </Typography>
      </div>
      <Information type="caution" icon={InfoCautionIcon}>
        <span>{t("사고(보험)이력으로 사고 날짜와 보험처리 금액은 알 수 있지만, ")}</span>
        <span css={{ color: colors.caution_3 }}>{t("수리한 부위는 알 수 없어요.")}</span>
        <br />
        <br />
        <span>☝ {t("딜러분도 직접 차량을 평가해야 알 수 있어요")}.</span>
      </Information>
    </div>
  );
};

export default CarFrameSection;
