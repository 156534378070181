export const i18n = {
  en: {
    "현장감가 기준": "On-site price reduction standard",
    "현장 검차시 미리 고지하지 않은 감가 요소가 추가로 발견된다면, 감가될 수 있어요.":
      "If additional matters for price reduction that were not previously notified are discovered during on-site vehicle inspection, they can lead to reduction of price.",
    "감가금액은 아래 기준에 따라 제한하고 있어요.":
      "The amount of price reduction is limited according to the below criteria.",
    "걱정하지 마세요": "Don’t worry.",
    "차량의 상태와 사고이력을 정확히 입력했다면, 현장감가는 발생되지 않아요.":
      "If the condition and accident history of a vehicle are accurately entered, on-site reduction of price will not occur.",
    "만약 미리 고지한 내용으로 감가됐다면, 본사에서 책임지고 환급해드려요.":
      "If reduction of price has occurred based on the pre-notified information, a main office will take responsibility and give a refund.",
    "외판 손상": "Outer panel damage",
    "광택 시공에서 없어지지 않는 도색 손상, 찌그러짐은 흔한 감가 요소예요.":
      "Paint damage and dents that do not disappear in the process of gloss coating cause the price reduction often.Price often drops due to paint damage and dents that don't disappear during gloss coating.",
    "손상 부위가 여러개일 경우, 손상 갯수가 아닌 '판 단위'로 계산해요.":
      'If there are multiple areas of damage, they are calculated in terms of "panel unit" rather than the number of damages.',
    "승용차 기준 약 13판의 외판이 있어요.": "There are approximately 13 outer panels on a passenger vehicle.",
    "스크래치는 3개여도, 1판 수리비가 발생해요.":
      "Even though there are only 3 scratches, the cost of repairing one panel is incurred.",
    "스크래치는 1개지만, 2판 수리비가 발생해요.":
      "Even though there is only one scratch, the cost of repairing two panels is incurred.",
    "사이드미러 스크래치도 1판으로 계산해요.": "A scratch on the side mirror is also counted as one panel.",
    "앞 범퍼와 앞 휀다는 서로 다른 외판입니다.":
      "The front bumper and the front fender are considered separate exterior panels.",
    "필러 패널, 사이드실 패널, 사이드 미러도 각각 외판 1판에 해당합니다.":
      "Each of the pillar panel, rocker panel, and side mirror is counted as one exterior panel.",
    "복원 필요한 문콕": "Vehicle door damage to require restoration",
    "3 ~ 5만원 /개": "30,000 won ~ 50,000 won /one damage",
    "판금도색 (국산차)": "Sheet metal painting (domestic vehicle)",
    "10 ~ 13만원 /판": "100,000 won ~ 130,000 won /one sheet",
    "판금도색 (수입차)": "Sheet metal painting (imported vehicle)",
    "15 ~ 18만원 /판": "150,000 won ~ 180,000 won /one sheet",
    "과거 사고 이력": "Past accident history",
    "과거 사고가 있었다면, 완벽히 수리를 했더라도 추후 문제가 생길 가능성 때문에 감가 요소에 해당돼요.":
      "If there was a past accident, it corresponds to a factor of price reduction due to the possibility of causing an issue in the future even though it has been fully repaired.",
    "외판 판금·교환": "Replacement and welding of outer panel",
    "견적가의 3 ~ 4% /부위": "3 ~ 4% of an estimated price/one area",
    "골격 용접·판금·교환": "Replacement of structural welding sheet metal",
    "견적가의 5 ~ 7% /부위": "5 ~ 7% of an estimated price/one area",
    "범퍼 교환": "Replacement of bumper",
    "범퍼수리는 시세에 영향 없음": "Repairing a bumper is not affected by market prices.",
    "연식이 오래될수록 사고감가율이 낮아요.":
      "The older athe vehicle, the lower the rate of price reduction due to an accident is.the less impact an accident has on reducing the price.",
    "대형차일수록 사고감가율이 큰 편이에요.":
      "The larger a vehicle, the higher the rate of price reduction due to an accident tends to be.",
    "사고는 성능점검기록부, 볼트 체결 흔적 등으로 판단해요.":
      "A vehicle accident is assessed based on performance inspection records and traces of bolt fastening etc.",
    "사고(보험)이력으로 사고 날짜와 보험처리 금액은 알 수 있지만, ":
      "Through accident insurance history accident dates and insurance settlement amount can be grasped ",
    "수리한 부위는 알 수 없어요.": "however the repaired areas can not be found",
    "딜러분도 직접 차량을 평가해야 알 수 있어요": "A dealer should assess directly a vehicle to grasp it properly",
    "타이어, 휠 손상": "Damaged tire and wheel",
    "지면에 직접 닿는 부위인 만큼 휠 손상, 타이어 마모도 흔하게 발견돼요.":
      "Wheel damage and tire wear are frequently found as they touch the ground directly.",
    "타이어 트레드 20% 미만": "Tire tread is less than 20%.",
    "9 ~ 12만원 /짝": "90,000 won ~ 120,000 won /one unit",
    "휠 복원 (일반)": "Wheel restoration (general)",
    "5 ~ 10만원 /짝": "50,000 won ~ 100,000 won /one unit",
    "휠 복원 (다이아 컷팅)": "Wheel restoration (diamond cutting)",
    "8 ~ 13만원 /짝": "80,000 won ~ 130,000 won /one unit",
    "휠 복원 (크롬)": "Wheel restoration (chrome)",
    "10 ~ 20만원 /짝": "100,000 won ~ 200,000 won /one unit",
    "17~18인치 기준으로, 19인치 이상은 수리비가 올라가요.":
      "The repair cost increases for 19 inches or more size, being based on 17-18 inches size.",
    "스포츠 타이어, 수입 타이어는 수리비가 올라가요.":
      "The cost of repairing a tire increases for sports tires and imported tires.Repair costs for sports or imported tires are higher.",
    기타: "Others",
    "이 외에도 감가 요소는 아래와 같아요.": "In addition, other causes of price reduction are as follows.",
    "미납 과태료 있음": "There is an unpaid fine.",
    "내비게이션 등 전자장비 고장": "The malfunction of electronic equipments like a navigation",
    "심한 누유 등의 정비필요 문제 있음": "A maintenance is required due to severe oil leakage etc.",
    "키 분실": "Losing a key",
    "유리, 틴팅 필름 손상": "Damaged glass and tinting film",
  },
  ar: {
    "현장감가 기준": "معيار تقييم محتوى التخفيض في الموقع",
    "현장 검차시 미리 고지하지 않은 감가 요소가 추가로 발견된다면, 감가될 수 있어요.":
      "إذا تم اكتشاف عناصر تخفيض سعر قيمة السيارة الذي لم يتم إخطاره مسبقًا أثناء الفحص الموقعي، فقد يتم تخفيض سعر قيمة السيارة.",
    "감가금액은 아래 기준에 따라 제한하고 있어요.":
      "مبلغ تخفيض سعر من قيمة السيارة محدود وفقًا للمعايير الموضحة أدناه.",
    "걱정하지 마세요": "لا تقلق",
    "차량의 상태와 사고이력을 정확히 입력했다면, 현장감가는 발생되지 않아요.":
      "إذا قمت بإدخال حالة السيارة وتاريخ الحوادث بدقة، فلن يحدث أي تخفيض من قيمة السيارة في الموقع.",
    "만약 미리 고지한 내용으로 감가됐다면, 본사에서 책임지고 환급해드려요.":
      "إذا تم تخفيض السعر بسبب المعلومات التي تم إبلاغك عنها مسبقًا، فسيكون مكتبنا الرئيسي مسؤولاً عن استرداد الأموال.",
    "외판 손상": "تلف الهيكل الخارجي",
    "광택 시공에서 없어지지 않는 도색 손상, 찌그러짐은 흔한 감가 요소예요.":
      "يعد تلف الطلاء والخدوش التي لا تختفي أثناء عمل التلميع من العوامل الشائعة لتخفيض سعر قيمة السيارة.",
    "손상 부위가 여러개일 경우, 손상 갯수가 아닌 '판 단위'로 계산해요.":
      "إذا كان هناك العديد من المناطق التالفة، فسيتم حسابها على أساس كل لوحة بدلاً من عدد الأجزاء التالفة.",
    "승용차 기준 약 13판의 외판이 있어요.": "هناك حوالي 13 جزء من الهيكل الخارجي لسيارات.",
    "스크래치는 3개여도, 1판 수리비가 발생해요.": "حتى لو كان هناك 3 خدوش، سيتم تحمل تكاليف إصلاح لوحة كاملة.",
    "스크래치는 1개지만, 2판 수리비가 발생해요.": "حتى لو كان هناك خدش واحد، سيتم تحمل تكاليف إصلاح لوحتين كاملتين.",
    "사이드미러 스크래치도 1판으로 계산해요.": "يُحسب الخدش في المرآة الجانبية أيضًا كلوحة واحدة",
    "앞 범퍼와 앞 휀다는 서로 다른 외판입니다.": "يُعتبر المصد الأمامي والرفرف الأمامي لوحين خارجيين مختلفين",
    "필러 패널, 사이드실 패널, 사이드 미러도 각각 외판 1판에 해당합니다.":
      "كل من لوحة العمود ولوحة العتبة الجانبية والمرآة الجانبية تُحسب كلوحة خارجية واحدة",
    "복원 필요한 문콕": "الخدوش على باب السيارة في حاجة إلى الترميم",
    "3 ~ 5만원 /개": "30.000 إلى 50.000 وون كوري للقطعة الواحدة",
    "판금도색 (국산차)": "طلاء الصفائح المعدنية (السيارات الكورية)",
    "10 ~ 13만원 /판": "100.000 إلى 130.000 وون كوري للوحة الواحدة",
    "판금도색 (수입차)": "طلاء الصفائح المعدنية (السيارات غير الكورية)",
    "15 ~ 18만원 /판": "150.000 إلى 180.000 وون كوري للوحة الواحدة",
    "과거 사고 이력": "تاريخ الحوادث الماضية",
    "과거 사고가 있었다면, 완벽히 수리를 했더라도 추후 문제가 생길 가능성 때문에 감가 요소에 해당돼요.":
      "إذا كان هناك حادث في الماضي، حتى لو تم إصلاحه بالكامل، فهو عامل تخفيض سعر قيمة السيارة بسبب احتمال حدوث مشاكل في المستقبل.",
    "외판 판금·교환": "الخدوش, طلاء على باب السيارة في حاجة إلى الترميم",
    "견적가의 3 ~ 4% /부위": "من 3 إلى 4% من السعر المعروض / لكل جزء من السيارة",
    "골격 용접·판금·교환": "لحام الهيكل العظمي للسيارة، تبديل اللوحات المعدنية للسيارة",
    "견적가의 5 ~ 7% /부위": "من 5 إلى 7% من السعر المعروض / لكل جزء من السيارة",
    "범퍼 교환": "تبديل مصد السيارة",
    "범퍼수리는 시세에 영향 없음": "إصلاح المصد ليس له أي تأثير على سعر السوق.",
    "연식이 오래될수록 사고감가율이 낮아요.": "كلما كان الطراز أقدم، انخفض معدل انخفاض الحوادث.",
    "대형차일수록 사고감가율이 큰 편이에요.": "كلما كانت السيارة أكبر، كلما ارتفع معدل الحوادث.",
    "사고는 성능점검기록부, 볼트 체결 흔적 등으로 판단해요.":
      "يتم الحكم على الحوادث بناءً على سجلات فحص الأداء، وآثار تشديد البراغي، وما إلى ذلك.",
    "사고(보험)이력으로 사고 날짜와 보험처리 금액은 알 수 있지만, ":
      "يمكنك معرفة سجل تاريخ الحادث وقيمة التأمين من خلال تاريخ الحادث (التأمين)، ",
    "수리한 부위는 알 수 없어요.": "لكن لا يمكنه معرفة الجزء الذي تم إصلاحه.",
    "딜러분도 직접 차량을 평가해야 알 수 있어요":
      "لا يمكن لوكيل تاجر السيارات معرفة ذلك إلا إذا قام الوكيل بتقييم السيارة شخصيًا.",
    "타이어, 휠 손상": "تلف الإطارات والعجلات",
    "지면에 직접 닿는 부위인 만큼 휠 손상, 타이어 마모도 흔하게 발견돼요.":
      "نظرًا لأنها منطقة تتلامس بشكل مباشر مع الأرض، فمن الشائع حدوث تلف في العجلات وتآكل الإطارات.",
    "타이어 트레드 20% 미만": "أقل من 20% من مداس الإطارات",
    "9 ~ 12만원 /짝": "90.000 إلى 120.000 وون كوري للقطعة الواحدة",
    "휠 복원 (일반)": "ترميم العجلات (عام)",
    "5 ~ 10만원 /짝": "50.000 إلى 100.000 وون كوري للقطعة الواحدة",
    "휠 복원 (다이아 컷팅)": "ترميم العجلات (يتم تطبيق عملية قطع الماسي)",
    "8 ~ 13만원 /짝": "80.000 إلى 130.000 وون كوري للقطعة الواحدة",
    "휠 복원 (크롬)": "ترميم العجلات (الكروم)",
    "10 ~ 20만원 /짝": "100.000 إلى 200.000 وون كوري للقطعة الواحدة",
    "17~18인치 기준으로, 19인치 이상은 수리비가 올라가요.":
      "ما ورد أعلاه بناءً على مقاس 17 إلى 18 بوصة، تزيد تكاليف الإصلاح إذا كانت مقاس 19 بوصة أو أكثر.",
    "스포츠 타이어, 수입 타이어는 수리비가 올라가요.": "الإطارات الرياضية والإطارات غير الكورية لها تكاليف إصلاح أعلى.",
    기타: "أخرى",
    "이 외에도 감가 요소는 아래와 같아요.": "بالإضافة إلى ذلك، فإن عوامل تخفيض سعر قيمة السيارة هي كما يلي.",
    "미납 과태료 있음": "هناك غرامة لعدم الدفع.",
    "내비게이션 등 전자장비 고장": "فشل المعدات الإلكترونية مثل الملاحة",
    "심한 누유 등의 정비필요 문제 있음": "المشاكل التي تتطلب الصيانة، مثل التسرب الشديد للزيت",
    "키 분실": "ضياع المفتاح",
    "유리, 틴팅 필름 손상": "تلف الزجاج وفيلم التظليل",
  },
};
