import React from "react";

import { toast } from "@PRNDcompany/heydealer-ui";
import { useMutation } from "@tanstack/react-query";
import { map, forEach, size } from "lodash";

import pxios from "../../../apis/pxios";
import { setCarToken } from "../../../cores/setCarToken";
import type { ImageInput } from "../../../declaration/server";

import EmptyFile from "./EmptyFile/EmptyFile";
import SingleImage from "./SingleImage/SingleImage";

import styles from "./ImageInputList.module.scss";

interface Props {
  images: ImageInput[];
  onChangeImages: (images: ImageInput[]) => void;
  hashId: string;
  uploadPath: string;
}

const ImageInputList: React.FC<Props> = ({ images, onChangeImages, hashId, uploadPath }) => {
  const uploadFilesMutation = useMutation({
    mutationFn: (data: FormData) =>
      pxios.post<ImageInput[]>(`/v2/customers/web/cars/${hashId}/${uploadPath}/`, data, setCarToken()),
  });

  const { isLoading } = uploadFilesMutation;

  const onCreate = async (_files: FileList | null) => {
    if (_files === null) {
      return;
    }

    if (size(images) + _files.length > 3) {
      toast.default("최대 3장 까지 업로드 할 수 있습니다.");

      return;
    }

    const formData = new FormData();
    forEach(_files, (file) => {
      formData.append("images", file);
    });

    formData.set("type", "id_card");
    const { data } = await uploadFilesMutation.mutateAsync(formData);
    onChangeImages(images.concat(data));
  };

  const onDelete = (index: number) => {
    onChangeImages(images.filter((_, i) => i !== index));
  };

  return (
    <>
      <div className={styles.imageFilesWrapper}>
        {map(images, (file, i) => (
          <SingleImage key={i} file={file} onDelete={() => onDelete(i)} />
        ))}
        <EmptyFile onCreate={onCreate} isLoading={isLoading} />
      </div>
    </>
  );
};

export default ImageInputList;
