import type { MouseEvent } from "react";
import React from "react";

import { get } from "lodash";
import Ink from "react-ink";

import type { ImageInput } from "../../../../declaration/server";

import styles from "./SingleImage.module.scss";

interface Props {
  file: ImageInput;
  onDelete: () => void;
}

const SingleImage: React.FC<Props> = ({ file, onDelete }) => {
  const onDeleteImage = (e: MouseEvent) => {
    e.stopPropagation();
    onDelete();
  };

  const actionIcons = (
    <>
      <label className={styles.delete} onClick={onDeleteImage}>
        <Ink />
      </label>
    </>
  );

  const renderPreview = () => {
    const url = get(file, "url");

    return (
      <div className={styles.imageWrapper}>
        <a href={url} target={"_blank"}>
          <img src={url} />
        </a>
        {actionIcons}
      </div>
    );
  };

  return <>{renderPreview()}</>;
};

export default SingleImage;
