/**
 * 이벤트 로그 인터페이스
 * cf. https://docs.google.com/spreadsheets/d/1cdfQ4gyNZ6jKZejidH9BWTk8Jv5Kd1PdmujhWFMP5dU/edit?pli=1#gid=759081213
 * - parameters.user_hash_id는 앱에서 추가해서 보내므로 여기서는 제외
 */

import { logEvent } from "@PRNDcompany/heydealer-lib";

import type { Car } from "../../types";

export const logClickPreInspectionProcessEvent = (
  car_hash_id: string | null,
  referrer: "zero_progress_content" | "market_sell_process_content"
) =>
  logEvent({
    event_name: "click_pre_inspection_process",
    event_description: "(zero 신청) 평가사가 뭘 진단하나요? 클릭",
    parameters: { ...(car_hash_id ? { car_hash_id } : {}), referrer },
    provider: { api: true, firebase: true, appsflyer: false, facebook: false },
  });

export const logVisitReductionStandardContentEvent = () =>
  logEvent({
    event_name: "visit_reduction_standard_content",
    event_description: "(컨텐츠) 감가기준",
    parameters: {},
    provider: { api: true, firebase: true, appsflyer: false, facebook: false },
  });

export const logVisitDetailAddressFormEvent = (car_hash_id: Car["hash_id"], car_status: Car["status"]) => {
  logEvent({
    event_name: "visit_detail_address_form",
    event_description: "(zero 기타) zero > 진단 예약 리마인드 알림톡 > 진단 차량 상세 위치 폼 진입",
    parameters: { car_hash_id, car_status },
    provider: { api: true, firebase: true, appsflyer: false, facebook: false },
  });
};

export const logVisitFixedPriceZeroRegisterEvent = (
  car_hash_id: Car["hash_id"],
  car_status: Car["status"],
  referrer: string
) => {
  logEvent({
    event_name: "visit_fixed_price_zero_register",
    event_description: "(희망가 zero) 희망가 제안하기 page 진입",
    parameters: { car_hash_id, car_status, referrer },
    provider: { api: true, firebase: true, appsflyer: false, facebook: false },
  });
};

export * from "./logClickBoostFixedPriceZeroEvent";
export * from "./logClickRegisterFixedPriceZeroEvent";
export * from "./logVisitChronicProblemsEvent";
