import React from "react";

import { Typography } from "@PRNDcompany/heydealer-ui";
import classNames from "classnames";

import styles from "./Process.module.scss";

interface Props {
  title: string;
  desc: string;
}

const Process: React.FC<Props> = ({ title, desc }) => {
  return (
    <div className={classNames(styles.process, styles.dot)}>
      <Typography variant="subtitle_3" color="black_6">
        {title}
      </Typography>
      <Typography variant="body_2" color="black_6">
        {desc}
      </Typography>
      <div className={styles.bigDot} />
    </div>
  );
};

export default Process;
