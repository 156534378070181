/** @jsxImportSource @emotion/react */

import type { FC, PropsWithChildren } from "react";
import React from "react";

import { css } from "@emotion/react";

type LayoutProps = PropsWithChildren<{
  className?: string;
}>;

const containerStyle = css`
  margin: 0 auto;

  font-family: "Spoqa Han Sans", "SDGothicNeo1", "Malgun Gothic", Sans-Serif, serif;
  max-width: 600px;
  min-height: 100vh;
`;

const Layout: FC<LayoutProps> = ({ className, children }) => {
  return (
    <div css={containerStyle} className={className}>
      {children}
    </div>
  );
};

export default Layout;
