import type { DetailedHTMLProps, InputHTMLAttributes } from "react";
import React from "react";

import classNames from "classnames";

import Typography from "../../../../components/Typography/Typography";

import { ReactComponent as CheckIcon } from "./CheckIcon.svg";

import styles from "./Checkbox.module.scss";

interface Props {
  // style
  label?: string;
  variant?: "list" | "box";
  size?: "big" | "small";
  warning?: boolean;

  // customize
  className?: string;
}

const Checkbox: React.FC<Props & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>> = ({
  label = "",
  variant = "list",
  warning = false,
  size = "small",

  // customize
  className = "",
  ...otherProps
}) => {
  return (
    <label
      htmlFor={"checkboxInput"}
      className={classNames(
        styles.wrapper,
        styles[size],
        styles[variant],
        otherProps.checked && styles.checked,
        warning && styles.warning,
        otherProps.disabled && styles.disabled,
        className
      )}
    >
      <input
        type={"checkbox"}
        id={"checkboxInput"}
        {...otherProps}
        style={{ display: "none" }}
        onChange={otherProps.onChange}
      />
      <span className={classNames(styles.checkbox)}>
        {otherProps.checked && <CheckIcon className={styles.checkIcon} />}
      </span>
      <Typography
        className={classNames(styles.label, otherProps.disabled ? styles.disabledTypo : "")}
        variant={size === "big" ? "Body_16" : "Body_14"}
      >
        {label}
      </Typography>
    </label>
  );
};

export default Checkbox;
