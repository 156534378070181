/** @jsxImportSource @emotion/react */

import type { FC } from "react";

import { Typography, Divider } from "@PRNDcompany/heydealer-ui";

import { sectionTextAreaStyle, sectionWrapperStyle, tableRowStyle } from "../styles";
import { useTranslation } from "../useTranslation";

const EtcSection: FC = () => {
  const { t } = useTranslation();

  return (
    <div css={sectionWrapperStyle}>
      <div css={sectionTextAreaStyle}>
        <Typography variant="subtitle_1">{t("기타")}</Typography>
        <div>
          <Typography variant="body_1">{t("이 외에도 감가 요소는 아래와 같아요.")}</Typography>
        </div>
      </div>
      <div css={{ display: "grid", gap: "1rem" }}>
        <div>
          <div css={tableRowStyle}>
            <Typography variant="subtitle_3">{t("미납 과태료 있음")}</Typography>
          </div>
          <Divider />
          <div css={tableRowStyle}>
            <Typography variant="subtitle_3">{t("내비게이션 등 전자장비 고장")}</Typography>
          </div>
          <Divider />
          <div css={tableRowStyle}>
            <Typography variant="subtitle_3">{t("심한 누유 등의 정비필요 문제 있음")}</Typography>
          </div>
          <Divider />
          <div css={tableRowStyle}>
            <Typography variant="subtitle_3">{t("키 분실")}</Typography>
          </div>
          <Divider />
          <div css={tableRowStyle}>
            <Typography variant="subtitle_3">{t("유리, 틴팅 필름 손상")}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EtcSection;
