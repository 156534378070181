import { assert } from "@PRNDcompany/heydealer-lib";
import { colors } from "@PRNDcompany/heydealer-ui";

import type { FAQ } from "../../customer/apis";

const convertQueryToRegExpString = (query: string): string =>
  query
    .trim()
    .replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
    .replace(/\s+/g, "|");

export const scoreFAQ = (faq: FAQ, query: string): number => {
  // [Disclaimer] 기획 당시 일치검색만 고려하였기에 점수화 로직 기획에서 정의된 바 없음; 검색 고도화하면서 임의 구현
  const queryRegExp = new RegExp(convertQueryToRegExpString(query), "gi");

  const titleText = new DOMParser().parseFromString(faq.title, "text/html").body.textContent;
  const titleScore = titleText?.match(queryRegExp)?.length ?? 0;

  const contentText = new DOMParser().parseFromString(faq.content, "text/html").body.textContent;
  const contentScore = contentText?.match(queryRegExp)?.length ?? 0;

  return 2 * titleScore + contentScore;
};

export const highlightQuery = (htmlText: string, query: string): string => {
  const queryRegExpString = convertQueryToRegExpString(query);
  if (!queryRegExpString) {
    return htmlText;
  }

  const queryRegExp = new RegExp(queryRegExpString, "gi");
  const queryGroupingRegExp = new RegExp(`(${queryRegExpString})|.`, "gi");

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlText, "text/html");
  const iterator = document.createNodeIterator(doc.body, NodeFilter.SHOW_TEXT, (node) =>
    node.textContent?.match(queryRegExp) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT
  );

  const nodes = [];
  while (iterator.nextNode()) {
    nodes.push(iterator.referenceNode);
  }

  for (const node of nodes) {
    const text = node.textContent;
    assert(!!text, "Node iterator should return text node.");

    const parent = node.parentNode;
    assert(!!parent, "Node should have parent node.");

    Array.from(text.matchAll(queryGroupingRegExp))
      .map(([originalText, queryMatch]) =>
        queryMatch
          ? Object.assign(document.createElement("span"), {
              style: `color: ${colors.brand_2}`,
              textContent: queryMatch,
            })
          : document.createTextNode(originalText)
      )
      .forEach((n) => parent.insertBefore(n, node));

    parent.removeChild(node);
  }

  return doc.body.innerHTML;
};
