/** @jsxImportSource @emotion/react */
import React from "react";

import { Button, colors } from "@PRNDcompany/heydealer-ui";
import taxios from "@PRNDcompany/taxios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { map } from "lodash";

import pxios from "../../../apis/pxios";
import { ReactComponent as ReConfirm } from "../../../images/re_confirm.svg";
import type { AttachmentResponse } from "../../../pages/MortgageAndAttachmentPage/responseTypes";
import Typography from "../../Typography/Typography";
import Border from "../Border/Border";
import Emoji from "../Emoji/Emoji";
import Information from "../Information/Information";
import LoadingItem from "../LoadingItem/LoadingItem";
import styles from "../mortgageAndAttachment.module.scss";
import NoItem from "../NoItem/NoItem";

interface Props {
  hashId: string;
}

const Attachment: React.FC<Props> = ({ hashId }) => {
  const {
    data: attachmentResponse,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: [`/v2/customers/cars/${hashId}/attachment/`],
    queryFn: async () => (await pxios.get<AttachmentResponse>(`/v2/customers/cars/${hashId}/attachment/`)).data,
  });

  const { mutate: refetchAttachments, isLoading } = useMutation({
    mutationKey: ["cars", hashId, "attachment"],
    mutationFn: async () => (await taxios.post(`/v2/customers/cars/${hashId}/attachment/`)).data,
    onSuccess: () => refetch(),
  });

  // 조회중이거나 재조회중
  const isChecking = isFetching || isLoading;

  if (isChecking) {
    return (
      <section className={styles.container}>
        <header className={styles.header}>
          <div className={styles.row}>
            <Typography variant="Subtitle_20">압류</Typography>
          </div>
        </header>
        <LoadingItem />
      </section>
    );
  }

  if (!attachmentResponse) {
    return null;
  }

  const isNoItem = attachmentResponse.count === 0;
  const isFailedInternalDataExists = attachmentResponse.is_failed;

  const attachmentHeaderTitle = (
    <div className={styles.row}>
      <Typography variant="Subtitle_20">압류</Typography>
      <Typography variant="Subtitle_16">{attachmentResponse.count ?? "-"}건</Typography>
    </div>
  );

  const attachmentHeaderTime = (
    <div className={styles.row}>
      <Typography variant="Body_14" className={styles.time}>
        <>조회시간 {attachmentResponse.updated_at}</>
      </Typography>
      <Button onClick={() => refetchAttachments()} variant="secondary" size={40}>
        <div css={{ display: "grid", gridTemplateColumns: "auto 1fr", alignItems: "center", gap: "0.25rem" }}>
          <ReConfirm className={styles.reConfirmIcon} />
          재조회
        </div>
      </Button>
    </div>
  );

  const attachmentFailedItem = (
    <Information variant="error" title="조회실패">
      압류내용을 불러오지 못했어요. 잠시 후 다시 시도해주세요.
    </Information>
  );

  if (isNoItem) {
    return (
      <section className={styles.container}>
        <header className={styles.header}>
          {attachmentHeaderTitle}
          {attachmentHeaderTime}
        </header>
        <NoItem text={"압류 내용이 없어요"} />
      </section>
    );
  }

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        {attachmentHeaderTitle}
        {attachmentHeaderTime}
      </header>
      <section className={styles.infoSection}>
        {isFailedInternalDataExists ? attachmentFailedItem : <br />}
        {map(attachmentResponse.attachments, (item, i) => {
          return (
            item && (
              <Information key={i} variant={item.is_warning ? "warning" : "critical"} title={item.date || ""}>
                <Typography variant="Body_14">{item.description}</Typography>
                <Typography variant="Subtitle_14">촉탁기관: {item.institution ?? "-"}</Typography>
                {item.institution_phone_number && (
                  <Typography variant="Body_14">
                    📞{" "}
                    <a css={{ color: colors.base_primary }} href={`tel:${item.institution_phone_number}`}>
                      <b>{item.institution_phone_number}</b>
                    </a>
                  </Typography>
                )}
                {item.is_fallback_phone_number && (
                  <Typography variant="Body_14">📌 상담사님께 촉탁기관 전화번호를 문의하세요.</Typography>
                )}
              </Information>
            )
          );
        })}
      </section>
      <Border height={1} className={styles.border} />
      <div className={styles.paragraph}>
        <Typography variant="Subtitle_16" className={styles.subTitle}>
          압류란?
        </Typography>
        <Typography variant="Body_14">
          세금이나 과태료, 통행료 등이 미납됐을 때, 이를 등록원부에 기록하는 것 입니다.
        </Typography>
      </div>
      <div className={styles.paragraph}>
        <Typography variant="Subtitle_16" className={styles.subTitle}>
          압류 해지방법
        </Typography>
        <Typography variant="Body_14">촉탁기관마다 따로 요청해서 해지해야 합니다.</Typography>
      </div>
      <div className={styles.paragraph}>
        <Typography variant="Body_14" className={styles.subTitle2}>
          <Emoji name="white-up-pointing-index" className={styles.emoji} />
          유의하실 점
        </Typography>
        <Typography variant="Body_14">
          촉탁기관이 지역명(ex. 서울시 강남구)인 경우, 지방관청(ex. 구청, 시청)에 요청해주세요.
        </Typography>
      </div>
      <div className={styles.paragraph}>
        <Typography variant="Body_14" className={styles.warningText}>
          [4대보험 체납] <br />
          해지비용이 클 수 있으니 국민건강보험공단(1577-1000)에 연락해 정확한 금액과 해지방법을 안내 받으세요.
        </Typography>
      </div>
      <div className={styles.paragraph}>
        <Typography variant="Body_14">
          [세금, 과태료] <br />
          촉탁기관에 전화하여 “압류 해지를 위해 납부하려고 한다”고 말씀하시면, 가상계좌가 문자로 발급됩니다.
        </Typography>
      </div>
      <div className={styles.paragraph}>
        <Typography variant="Body_14">
          [통행료] <br />
          한국도로공사 홈페이지 또는 전화(1588-2504)를 이용하거나, 전국 GS편의점 · CU에 방문해서 납부가능합니다.
        </Typography>
      </div>
    </section>
  );
};

export default Attachment;
