import type { HTMLProps } from "react";
import React from "react";

import classNames from "classnames";
import { useInView } from "react-intersection-observer";

import styles from "./ShowInView.module.scss";

const ShowInView: React.FC<HTMLProps<HTMLDivElement>> = ({ className, ...rest }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return <div ref={ref} className={classNames(className, styles.showInView, inView ? styles.visible : "")} {...rest} />;
};

export default ShowInView;
