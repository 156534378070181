import type { ReactNode } from "react";
import React from "react";

import Typography from "../../../components/Typography/Typography";
import theHorns from "../../../images/the-horns.png";

import styles from "./AllDone.module.scss";

interface Props {
  title: ReactNode;
  description: ReactNode;
}

const AllDone: React.FC<Props> = ({ title, description }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <img src={theHorns} alt={""} className={styles.emoji} />
        <Typography variant={"Headline_24"} className={styles.bigText}>
          {title}
        </Typography>
        <Typography variant={"Body_16"}>{description}</Typography>
      </div>
    </div>
  );
};

export default AllDone;
