import type { Dispatch, SetStateAction } from "react";
import React, { useEffect, useState } from "react";

import { ResizeObserver } from "@juggle/resize-observer";
import classNames from "classnames";
import { get } from "lodash";
import useMeasure from "react-use-measure";

import LightInk from "../../components/LightInk/LightInk";
import Typography from "../../components/Typography/Typography";
import icArrowDown from "../../images/ic_arrow_down_gray.svg";

import { GenerateJSX } from "./Contents";
import type { Box } from "./SellRequiredDocsPage";

import boxStyles from "./Box.module.scss";
import styles from "./BoxDisplay.module.scss";

interface Props {
  boxData: Box;
  openedIndex: number | null;
  index: number;
  setOpenedIndex: Dispatch<SetStateAction<number | null>>;
}

const BoxDisplay = ({ boxData, openedIndex, index, setOpenedIndex }: Props) => {
  const [content, setContent] = useState<JSX.Element>(<></>);

  const [contentHeight, setContentHeight] = useState(0);

  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver });
  const height = get(bounds, "height");
  const isOpen = openedIndex === index;

  useEffect(() => {
    setContentHeight(height);
    window.addEventListener("resize", () => setContentHeight(height));

    return window.removeEventListener("resize", () => setContentHeight(height));
  }, [height]);

  const toggle = () => {
    if (isOpen) {
      setOpenedIndex(null);
    } else {
      setOpenedIndex(index);
    }
  };

  useEffect(() => {
    const content = <GenerateJSX data={boxData} />;
    setContent(content);
  }, [boxData]);

  const contentWrapperInlineStyle = {
    ...(isOpen ? { height: contentHeight, opacity: 1 } : { height: 0, opacity: 0 }),
    transitionDuration: `${Math.sqrt(contentHeight) / 150}s`,
  };

  return (
    <div className={classNames(styles.boxWrapper, { [styles.isOpen]: isOpen })}>
      <div className={boxStyles.box}>
        <img className={styles.arrowIcon} src={icArrowDown} alt={"v"} />
        <div className={styles.boxTitleWrap}>
          <Typography variant={isOpen ? "subtitle_s" : "body"}>{boxData.title}</Typography>
          <div className={boxStyles.inkWrapper} onClick={toggle}>
            <LightInk />
          </div>
        </div>
        <div className={styles.contentWrapper} style={contentWrapperInlineStyle}>
          <div ref={ref}>{isOpen && content}</div>
        </div>
      </div>
    </div>
  );
};

export default BoxDisplay;
