import taxios from "@PRNDcompany/taxios";

import { BASE_PATH } from "../../../BASE_PATH";

type GetCarDashboardImageResponse = {
  car_number: string;
  dashboard_image_url: string;
  other_image_urls: string[];
};

export const createGetCarDashboardImageAPI = (hashId: string) => async () => {
  return (await taxios.get<GetCarDashboardImageResponse>(`${BASE_PATH}/cars/${hashId}/dashboard_image/`)).data;
};
