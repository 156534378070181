/** @jsxImportSource @emotion/react */

import type { FC } from "react";

import { close, isAppWebview, open } from "@PRNDcompany/heydealer-lib";
import {
  DeprecatedAppBar as AppBar,
  ArrowRightIcon,
  Button,
  colors,
  HumanThumbnail,
  StrokeCloseIcon,
  Typography,
} from "@PRNDcompany/heydealer-ui";
import { useLocation } from "react-router";

import Page from "../../components/Page";
import ShowInView from "../../components/ShowInView";
import Video from "../../components/Video";
import useScrollPosition from "../../hooks/useScrollPosition";
import { logClickPreInspectionProcessEvent } from "../customer/modules/logEvent";

import HeydealerImg from "./assets/img/heydealer.png";
import PreCustomerProgress1 from "./assets/img/pre_customer_progress1.png";
import PreCustomerProgress2 from "./assets/img/pre_customer_progress2.mp4";
import PreCustomerProgress3 from "./assets/img/pre_customer_progress3.png";
import PreCustomerProgress4 from "./assets/img/pre_customer_progress4.png";
import PreCustomerProgress5 from "./assets/img/pre_customer_progress5.png";
import PreCustomerProgress6 from "./assets/img/pre_customer_progress6.mp4";
import PreCustomerProgress7 from "./assets/img/pre_customer_progress7.mp4";
import PreCustomerProgress8 from "./assets/img/pre_customer_progress8.png";
import ProgressTitle from "./components/ProgressTitle";
import SafeTradeSection from "./components/SafeTradeSection";
import { 평가사가뭘진단하나요ButtonStyle } from "./style";

import styles from "./PreInspectedCarProgressForCustomer.module.scss";

const REQUIRED_DOCS_URL = "https://dict-heydealer.oopy.io/fab9dc87-3226-4e9f-875d-993f8821c373";

// TODO: sass, inline style emotion으로 전환하기

const PreInspectedCarProgressForCustomerPage: FC = () => {
  const scrollPosition = useScrollPosition();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const car_hash_id = searchParams.get("car_hash_id");

  return (
    <Page title="헤이딜러 Zero 거래과정" background="white_3">
      {isAppWebview && (
        <AppBar
          leftIcon={{ icon: StrokeCloseIcon, onClick: close }}
          sticky
          background={scrollPosition <= 0 ? "transparent" : `${colors.white_1}E6`}
        />
      )}
      <div className={styles.container}>
        <ShowInView className={styles.progress}>
          <div className={styles.progressTextArea}>
            <ProgressTitle step={1} title="진단 예약하기" />
            <Typography variant="subtitle_1">
              내 차 진단장소와 시간을 <br />
              예약하세요.
            </Typography>
          </div>
          <div className={styles.progressImageArea} css={{ height: "11.5rem" }}>
            <img src={PreCustomerProgress1} alt="진단 예약하기" css={{ width: "12.5rem" }} />
          </div>
        </ShowInView>
        <ShowInView className={styles.progress}>
          <div css={{ display: "grid", gap: "1rem" }}>
            <div className={styles.progressTextArea}>
              <ProgressTitle step={2} title="출장 진단받기" />
              <Typography variant="subtitle_1">
                전문평가사 방문 후 <br />
                경매에 올려드려요.
              </Typography>
              <Typography variant="body_2" color="black_6">
                평균 20분 내로 끝나요.
              </Typography>
            </div>
            <div
              css={평가사가뭘진단하나요ButtonStyle}
              onClick={() => {
                if (isAppWebview) {
                  logClickPreInspectionProcessEvent(car_hash_id, "zero_progress_content");
                  open({ url: new URL("/inspection-point", window.location.origin).toString() });
                } else {
                  window.open("/inspection-point", "_blank");
                }
              }}
            >
              <Typography variant="caption_1">평가사가 뭘 진단하나요?</Typography>
              <ArrowRightIcon size={16} />
            </div>
          </div>
          <div css={{ height: "14rem", display: "flex", justifyContent: "center" }}>
            <Video src={PreCustomerProgress2} />
          </div>
        </ShowInView>
        <ShowInView className={styles.progress}>
          <div className={styles.progressTextArea}>
            <ProgressTitle step={3} title="전국 11,000명 딜러 경쟁" />
            <Typography variant="subtitle_1">
              48시간 실시간 경매를 <br />
              앱에서 확인하세요.
            </Typography>
          </div>
          <div className={styles.progressImageArea} css={{ height: "11.25rem" }}>
            <img src={PreCustomerProgress3} alt="전국 9천명 딜러 경쟁" css={{ width: "12.5rem" }} />
          </div>
        </ShowInView>
        <ShowInView className={styles.progress}>
          <div className={styles.progressTextArea}>
            <ProgressTitle step={4} title="판매요청" />
            <Typography variant="subtitle_1">
              최고가 판매여부를 <br />
              3일 내에 결정하세요.
            </Typography>
            <Typography variant="body_2" color="black_6">
              경매 최고가에서 감가는 없어요.
            </Typography>
          </div>
          <div className={styles.progressImageArea} css={{ height: "10rem" }}>
            <img src={PreCustomerProgress4} alt="판매요청" css={{ width: "18.5rem", marginTop: "-0.25rem" }} />
          </div>
        </ShowInView>
        <ShowInView className={styles.progress}>
          <div className={styles.progressTextArea}>
            <ProgressTitle step={5} title="탁송예약" />
            <Typography variant="subtitle_1">
              딜러 만남없이 탁송으로 <br />
              거래가 진행됩니다.
            </Typography>
            <Typography variant="body_2" color="black_6">
              평일 기준 5일 뒤까지 탁송 예약가능
            </Typography>
          </div>
          <div className={styles.progressImageArea} css={{ height: "10.5rem" }}>
            <img src={PreCustomerProgress5} alt="탁송예약" css={{ width: "18.5rem", marginTop: "-0.25rem" }} />
          </div>
        </ShowInView>
        <ShowInView className={styles.progress}>
          <div className={styles.progressTextArea}>
            <ProgressTitle step={6} title="판매서류 준비" />
            <Typography variant="subtitle_1">
              주민센터 방문으로 <br />
              판매서류를 준비하세요.
            </Typography>
            <Typography variant="body_2" color="black_6">
              사업자는 추가서류 필요 <br />
              (헤이딜러 매니저가 별도 안내)
            </Typography>
            <Button
              variant="secondary"
              color="navy"
              size={48}
              onClick={() => {
                if (isAppWebview) {
                  open({ url: REQUIRED_DOCS_URL, toolbar: true });
                } else {
                  window.open(REQUIRED_DOCS_URL, "_blank");
                }
              }}
              arrow="right"
              customCSS={{ marginTop: "0.5rem" }}
            >
              판매 필요서류
            </Button>
          </div>
          <div
            css={{
              marginTop: "0.625rem",
              marginLeft: "auto",
              width: "9.125rem",
              height: "9rem",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Video src={PreCustomerProgress6} className={styles.판매서류비디오} />
          </div>
        </ShowInView>
        <ShowInView>
          <div className={styles.progress}>
            <div className={styles.progressTextArea}>
              <ProgressTitle step={7} title="탁송기사 도착 &amp; 입금받기" />
              <Typography variant="subtitle_1">
                서류 확인 후 <br />
                10분 뒤에 입금돼요.
                <br />
                떠나는 내 차와 인사하세요!
              </Typography>
            </div>
          </div>
          <div
            css={{
              width: "fit-content",
              height: "15.5rem",

              position: "relative",
            }}
          >
            <Video src={PreCustomerProgress7} className={styles.탁송기사도착비디오} />
            <div
              css={{
                position: "absolute",
                top: 0,
                bottom: "1.5rem",
                right: 0,
                width: "2.5rem",
                background: `linear-gradient(90deg, rgba(247, 248, 251, 0), rgba(247, 248, 251, 1))`,
              }}
            />
          </div>
        </ShowInView>
        <SafeTradeSection />
        <ShowInView className={styles.progress}>
          <div className={styles.progressTextArea}>
            <ProgressTitle step={8} title="명의이전 &amp; 자동차보험 환급" />
            <Typography variant="subtitle_1">
              이전이 완료되면, <br />
              남은 보험료 환급받으세요!
            </Typography>
            <Typography variant="body_2" color="black_6">
              영업일 2일 내 완료
            </Typography>
          </div>
          <div css={{ borderRadius: "0.5rem", background: colors.white_1, padding: "1.5rem" }}>
            <div
              css={{ display: "grid", gap: "0.875rem 0.5rem", gridTemplateColumns: "auto 1fr", alignItems: "start" }}
            >
              <HumanThumbnail src={HeydealerImg} size={28} option={{ resize: false }} />
              <div
                css={{
                  width: "100%",

                  boxSizing: "border-box",
                  padding: "0.75rem",
                  borderRadius: "0 0.5rem 0.5rem",
                  border: `1px solid ${colors.white_4}`,
                  background: colors.white_2,
                }}
              >
                <Typography variant="subtitle_3">[명의이전 완료]</Typography>
                <Typography variant="body_2">명의이전이 완료되었습니다.</Typography>
              </div>

              <div />
              <img
                src={PreCustomerProgress8}
                alt="명의이전 &amp; 자동차보험 환급"
                css={{
                  boxSizing: "border-box",
                  height: "6.25rem",
                  border: `1px solid ${colors.white_4}`,
                  borderRadius: "0.625rem",
                }}
              />
            </div>
          </div>
        </ShowInView>
      </div>
    </Page>
  );
};

export default PreInspectedCarProgressForCustomerPage;
