import type { FC } from "react";
import React, { memo, useEffect, useState } from "react";

import { close } from "@PRNDcompany/heydealer-lib";
import { IconButton, StrokeBackIcon, StrokeCloseIcon } from "@PRNDcompany/heydealer-ui";
import classNames from "classnames";

import styles from "./AppBar.module.scss";

interface Props {
  className?: string;
  isFixed?: boolean;
  back?: boolean;
}

const AppBar: FC<Props> = memo(({ className, isFixed, back = false }) => {
  const [scrollTop, setScrollTop] = useState(0);

  const onScroll = () => {
    setScrollTop(window.scrollY);
  };

  useEffect(() => {
    if (isFixed) {
      document.addEventListener("scroll", onScroll);

      return () => {
        document.removeEventListener("scroll", onScroll);
      };
    }
  }, [isFixed]);

  return (
    <div
      className={classNames(styles.appBar, className, {
        [styles.isFixed]: isFixed,
        [styles.isNotTop]: scrollTop !== 0,
      })}
    >
      <IconButton
        icon={back ? StrokeBackIcon : StrokeCloseIcon}
        size={24}
        onClick={back ? () => history.back() : () => close()}
        touchAreaSize={40}
      />
    </div>
  );
});

export default AppBar;
