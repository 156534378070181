/** @jsxImportSource @emotion/react */

import type { FC } from "react";

import { css } from "@emotion/react";
import { colors } from "@PRNDcompany/heydealer-ui";

type TaskProps = {
  variant: "done" | "ing" | "todo";
};

const containerStyle = css`
  display: inline-block;

  position: relative;

  width: 1.5rem;
  height: 1.5rem;
`;

const innerCircleStyle = css`
  display: inline-block;

  position: absolute;
  top: 50%;
  left: 50%;

  width: 0.5rem;
  height: 0.5rem;

  border-radius: 50%;

  background: ${colors.brand_2};

  transform: translate(-50%, -50%);
`;

const outerCircleStyle = css`
  display: inline-block;

  position: absolute;
  top: 50%;
  left: 50%;

  width: 1.5rem;
  height: 1.5rem;

  border-radius: 50%;

  background: ${colors.brand_2_alpha_16};

  transform: translate(-50%, -50%);
`;

const todoInnerCircleStyle = css`
  background: ${colors.white_5};
`;

const Task: FC<TaskProps> = ({ variant }) => {
  return (
    <span css={containerStyle}>
      {variant === "ing" && <span css={outerCircleStyle} />}
      <span css={[innerCircleStyle, variant === "todo" && todoInnerCircleStyle]} />
    </span>
  );
};

export default Task;
