/** @jsxImportSource @emotion/react */

import type { FC, PropsWithChildren, SVGProps } from "react";

import { css } from "@emotion/react";

type InfoItemProps = PropsWithChildren<{
  Icon: FC<SVGProps<SVGSVGElement>>;
}>;

const itemStyle = css`
  display: grid;
  gap: 0.5rem;

  grid-template-columns: 1.75rem 1fr;
  align-content: start;
`;

const itemIconImgStyle = css`
  width: 1.75rem;
  height: 1.75rem;
`;

const textAreaStyle = css`
  display: grid;
  gap: 0.5rem;
`;

const InfoItem: FC<InfoItemProps> = ({ Icon, children }) => (
  <div css={itemStyle}>
    <Icon css={itemIconImgStyle} />
    <div css={textAreaStyle}>{children}</div>
  </div>
);

export default InfoItem;
