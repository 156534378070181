import { useEffect, useState } from "react";

import { captureException } from "@sentry/react";

type TranslationConfig = { lang: string; data: Record<string, Record<string, string>> };

const moduleConfig: TranslationConfig = { lang: "ko", data: {} };
const handlers: ((config: TranslationConfig) => void)[] = [];

export const useTranslation = () => {
  const [config, setConfig] = useState<TranslationConfig>(moduleConfig);

  const configure = (lang: string, data: Record<string, Record<string, string>>) => {
    config.data = data;

    if (lang !== "ko" && !Object.keys(data).includes(lang)) {
      throw new Error(`Invalid lang: ${lang}`);
    }

    config.lang = lang;

    handlers.forEach((handler) => handler({ lang, data }));
  };

  const t = (key: string) => {
    if (config.lang === "ko") {
      return key;
    }

    const data = config.data[config.lang];

    if (!data) {
      return key;
    }

    if (data[key] === undefined) {
      if (process.env.NODE_ENV === "development") {
        throw new Error(`Missing translation: ${key} / lang: ${config.lang}`);
      }

      captureException(`Missing translation: ${key} / lang: ${config.lang}`);

      return key;
    }

    return data[key];
  };

  useEffect(() => {
    handlers.push(setConfig);

    return () => {
      handlers.filter((handler) => handler !== setConfig);
    };
  }, []);

  return { t, configure };
};
