import React, { useState } from "react";

import classNames from "classnames";
import { useLocation } from "react-router";

import Emoji from "../../components/MortgageAndAttachment/Emoji/Emoji";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import Typography from "../../components/Typography/Typography";
import sellRequireLeft from "../../images/sell_require_doc_left.png";
import sellRequireRight from "../../images/sell_require_doc_right.png";

import BoxDisplay from "./BoxDisplay";
import { entireContents } from "./Contents";

import styles from "./SellRequiredDocsPage.module.scss";

export interface Box {
  title?: string;
  type: "box" | "sub" | "cap" | "con" | "hr";
  info?: string | JSX.Element;
  contents?: Box[];
}

const SellRequiredDocsPage = () => {
  const [openedIndex, setOpenedIndex] = useState<number | null>(null);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isZero = params.get("is_zero") === "true";

  return (
    <PageWrapper title="판매 필요서류">
      <div className={styles.container}>
        {/* header */}
        <header className={styles.title}>
          <Typography variant={"headline_m"}>판매 필요서류</Typography>
        </header>
        <section>
          <div style={{ marginBottom: 4 }}>
            <Typography variant={"subtitle_m"}>개인 고객은 두가지만 준비해주세요.</Typography>
          </div>
          <Typography variant={"caption"} className={styles.captionNavy10}>
            공동명의 차량은 명의자 모두의 서류 필요
          </Typography>
          <div className={classNames(styles.flex, styles.docImgWrapper)}>
            <span>
              <img src={sellRequireLeft} alt={"자동차등록증 원본"} />
              <Typography variant={"body_m"}>자동차등록증 원본</Typography>
            </span>
            <span>
              <img src={sellRequireRight} alt={"자동차매도용 인감증명서"} />
              <Typography variant={"body_m"}>
                자동차매도용 인감증명서 <br />
                또는 본인서명사실확인서 <br />
              </Typography>
              <Typography variant={"caption"} className={styles.captionNavy10}>
                (매수자 인적사항 기재)
              </Typography>
            </span>
          </div>
          <section className={styles.export}>
            <Typography variant={"body_m"} className={styles.bold}>
              <Emoji className={styles.emoji} name={"white-up-pointing-index"} /> 수출 딜러에게 판매 시
            </Typography>
            <br />
            <Typography variant={"body_m"}>
              자동차매도용 인감증명서 대신 ‘신분증 사본’이 필요합니다. (수출 딜러여부는 판매요청 후 안내)
            </Typography>
          </section>
        </section>
        <hr className={styles.sectionSplit} />
        <section>
          {/* contents */}
          <div className={styles.boxesTitleWrapper}>
            <Typography variant={"subtitle"}>개인사업자(일반) 또는 법인인가요?</Typography>
          </div>
          {isZero && (
            <section className={styles.unnecessary}>
              <Typography variant={"body_m"} className={styles.bold}>
                <Emoji className={styles.emoji} name={"white-up-pointing-index"} /> 사업자 서류 미리 준비하지 않아도
                돼요.
              </Typography>
              <br />
              <Typography variant={"body_m"}>
                헤이딜러 Zero는 판매를 요청하면, 홈택스 계정을 연동할 수 있는데요. 이 때 대부분 서류가 자동 제출됩니다.{" "}
                <br />
                고객님에 따라 추가 서류가 필요할 수 있는데요. 헤이딜러 매니저가 따로 안내드릴게요.
              </Typography>
            </section>
          )}
          {entireContents.map((item, i) => (
            <BoxDisplay boxData={item} key={i} openedIndex={openedIndex} index={i} setOpenedIndex={setOpenedIndex} />
          ))}
        </section>
      </div>
    </PageWrapper>
  );
};

export default SellRequiredDocsPage;
