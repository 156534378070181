/** @jsxImportSource @emotion/react */
import React from "react";

import { Button, colors } from "@PRNDcompany/heydealer-ui";
import taxios from "@PRNDcompany/taxios";
import { useMutation, useQuery } from "@tanstack/react-query";
import map from "lodash/map";

import pxios from "../../../apis/pxios";
import { ReactComponent as ReConfirm } from "../../../images/re_confirm.svg";
import type { MortgageResponse } from "../../../pages/MortgageAndAttachmentPage/responseTypes";
import Typography from "../../Typography/Typography";
import Border from "../Border/Border";
import Emoji from "../Emoji/Emoji";
import Information from "../Information/Information";
import LoadingItem from "../LoadingItem/LoadingItem";
import styles from "../mortgageAndAttachment.module.scss";
import NoItem from "../NoItem/NoItem";

interface Props {
  hashId: string;
}

const Mortgage: React.FC<Props> = ({ hashId }) => {
  const {
    data: mortgageResponse,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: [`/v2/customers/cars/${hashId}/mortgage/`],
    queryFn: async () => (await pxios.get<MortgageResponse>(`/v2/customers/cars/${hashId}/mortgage/`)).data,
  });

  const { mutate: refetchMortgages, isLoading } = useMutation({
    mutationKey: ["cars", hashId, "mortgage"],
    mutationFn: async () => (await taxios.post(`/v2/customers/cars/${hashId}/mortgage/`)).data,
    onSuccess: () => refetch(),
  });

  // 조회중이거나 재조회중
  const isChecking = isFetching || isLoading;

  if (isChecking) {
    return (
      <section className={styles.container}>
        <header className={styles.header}>
          <div className={styles.row}>
            <Typography variant="Subtitle_20">저당</Typography>
          </div>
        </header>
        <LoadingItem />
      </section>
    );
  }

  if (!mortgageResponse) {
    return null;
  }

  const isNoItem = mortgageResponse.count === 0;
  const isFailedInternalDataExists = mortgageResponse.is_failed;

  const mortgageHeaderTitle = (
    <div className={styles.row}>
      <Typography variant="Subtitle_20">저당</Typography>
      <Typography variant="Subtitle_16">{mortgageResponse.count ?? "-"}건</Typography>
    </div>
  );

  const mortgageHeaderTime = (
    <div className={styles.row}>
      <Typography variant="Body_14" className={styles.time}>
        <>조회시간 {mortgageResponse.updated_at}</>
      </Typography>
      <Button onClick={() => refetchMortgages()} variant="secondary" size={40}>
        <div css={{ display: "grid", gridTemplateColumns: "auto 1fr", alignItems: "center", gap: "0.25rem" }}>
          <ReConfirm className={styles.reConfirmIcon} />
          재조회
        </div>
      </Button>
    </div>
  );

  const mortgageFailedItem = (
    <Information variant="error" title="조회실패">
      {mortgageResponse.count
        ? "저당내용을 불러오지 못했어요. 잠시 후 다시 시도해주세요."
        : "저당 상세 내용을 불러오지 못했어요. 잠시 후 다시 시도해주세요."}
    </Information>
  );

  if (isNoItem) {
    return (
      <section className={styles.container}>
        <header className={styles.header}>
          {mortgageHeaderTitle}
          {mortgageHeaderTime}
        </header>
        <NoItem text={"저당 내용이 없어요"} />
      </section>
    );
  }

  const failedText = <span className={styles.criticalText}>조회실패</span>;

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        {mortgageHeaderTitle}
        {mortgageHeaderTime}
      </header>
      <section className={styles.infoSection}>
        {isFailedInternalDataExists ? mortgageFailedItem : <br />}
        {map(
          mortgageResponse.mortgages,
          (item, i) =>
            item && (
              <Information variant="critical" title={`저당권 설정일 : ${item?.start_date}`} key={i}>
                • 채무자 : {item.debtor || failedText}
                <br />• 저당권 설정자 : {item.mortgagor || failedText}
                <br />• 저당권자 : {item.lender || failedText}
                {item.lender_phone_number && (
                  <Typography variant="Body_14">
                    📞{" "}
                    <a css={{ color: colors.base_primary }} href={`tel:${item.lender_phone_number}`}>
                      <b>{item.lender_phone_number}</b>
                    </a>
                  </Typography>
                )}
              </Information>
            )
        )}
      </section>
      <Border height={1} className={styles.border} />
      <div className={styles.paragraph}>
        <Typography variant="Subtitle_16" className={styles.subTitle}>
          저당이란?
        </Typography>
        <Typography variant="Body_14">
          보통 다음 두가지 경우에 생깁니다.
          <ul className={styles.dotUL}>
            <li>차량을 할부로 구매 (금융사가 체납 방지를 위해 담보 설정)</li>
            <li>차량 담보 대출 이용</li>
          </ul>
        </Typography>
      </div>
      <div className={styles.paragraph}>
        <Typography variant="Subtitle_16" className={styles.subTitle}>
          저당 해지방법
        </Typography>
        <Typography variant="Body_14">금융사에 연락해 금액을 확인 후 남은 할부를 완납(대출을 상환)해주세요.</Typography>
      </div>
      <div className={styles.paragraph}>
        <Typography variant="Body_14" className={styles.subTitle2}>
          <Emoji name="white-up-pointing-index" className={styles.emoji} />
          유의하실 점
        </Typography>
        <Typography variant="Body_14">
          <ul className={styles.dotUL}>
            <li>채권가액은 ‘남아있는 할부금액(대출 잔액)’과 다릅니다. 꼭 정확한 금액을 금융사를 통해 확인해주세요.</li>
            <li className={styles.criticalText}>금융사에 ‘저당권 해지 비용’까지 납부해야 저당이 완전히 해지됩니다.</li>
          </ul>
        </Typography>
      </div>
      <div className={styles.paragraph}>
        <Typography variant="Body_14" className={styles.subTitle2}>
          <Emoji name="white-up-pointing-index" className={styles.emoji} />
          차량 매각 대금으로 할부금을 갚고싶다면?
        </Typography>
        <Typography variant="Body_14">
          가능합니다. 다만 저당이 해지되어야 명의이전이 가능하기 때문에 꼭 대금을 받고 빠른 해지 부탁드려요.
        </Typography>
      </div>
    </section>
  );
};

export default Mortgage;
