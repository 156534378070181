import taxios from "@PRNDcompany/taxios";

import { BASE_PATH } from "../BASE_PATH";

export type GetFAQsParams = {
  type: string;
};

export type FAQ = {
  hash_id: string;
  title: string;
  content: string;
  group: string;
};

type GetFAQsResponse = FAQ[];

export const getFAQsAPI = async (params: GetFAQsParams) => {
  return (await taxios.get<GetFAQsResponse>(`${BASE_PATH}/faqs/`, { params })).data;
};
