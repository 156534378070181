import React from "react";

import loadingGif from "./Loading.gif";

import styles from "./LoadingItem.module.scss";

interface Props {}

const LoadingItem: React.FC<Props> = () => {
  return (
    <div className={styles.loadingWrapper}>
      <img className={styles.loadingGif} src={loadingGif} alt={"조회중입니다."} />
    </div>
  );
};

export default LoadingItem;
