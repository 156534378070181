/** @jsxImportSource @emotion/react */

import React, { useState } from "react";

import { css } from "@emotion/react";
import { Button, Typography } from "@PRNDcompany/heydealer-ui";

import styles from "./진단오류보상기준버튼.module.scss";

const 진단오류보상기준버튼: React.FC = () => {
  const [open, setOpen] = useState(false);

  const buttonStyle = css`
    margin-bottom: 4px;
    margin-left: -16px;
  `;

  const titleStyle = css`
    margin-bottom: 4px;

    font-weight: bold;
  `;

  const descriptionStyle = css`
    margin-bottom: 16px;
  `;

  const subTitleStyle = css`
    margin-bottom: 12px;
    margin-left: 1em;

    text-indent: -1em;
  `;

  const section2TitleStyle = css`
    margin-bottom: 4px;
  `;

  const notiStyle = css`
    margin-top: 8px;
    margin-bottom: 12px;
  `;

  return (
    <>
      <Button
        size={48}
        variant="text"
        css={buttonStyle}
        onClick={() => setOpen((prev) => !prev)}
        arrow={open ? "up" : "down"}
      >
        진단오류 보상기준
      </Button>
      {open && (
        <div>
          <Typography variant="body_2" css={titleStyle}>
            진단오류 보상기준 안내
          </Typography>
          <div className={styles.body}>
            <section>
              <Typography variant="body_2" css={descriptionStyle}>
                헤이딜러는 정확한 차량정보를 제공하기 위해 노력하고 있습니다. 중대한 진단오류가 있다면, 인수 후 2일 내
                클레임이 가능합니다.
              </Typography>
              <Typography variant="body_2" css={subTitleStyle}>
                1. 진단평가는 아래와 같은 한계가 있습니다. <br /> 참고하여 입찰 바랍니다.
              </Typography>
              <div className={styles.box}>
                <Typography variant="caption_1">
                  <ul>
                    <li>· 진단제외 : 도색판금여부, 문콕, 작은기스, 사용감, 미세누유, 소모품 상태, 썬팅손상 등 </li>
                    <li>· 외관진단 : 일반 상품화 기준 평가</li>
                    <li>· 누유진단 : 엔진룸 상부에서 제한적 평가</li>
                    <li>· 시운전없이 정차상태로 진단</li>
                  </ul>
                </Typography>
              </div>
            </section>
            <section>
              <Typography variant="body_2" css={subTitleStyle}>
                2. 그 외 진단오류는 아래 기준 내에서 보상해 드립니다.
              </Typography>
              <Typography variant="body_2" css={section2TitleStyle}>
                [ 사고감가 보상기준 ]
              </Typography>
              <Typography variant="caption_1">
                <table>
                  <tbody>
                    <tr>
                      <td className={styles.name}>외판 교환·판금</td>
                      <td className={styles.value}>
                        견적금액의 최대 2~3% / 부위 <br /> ※ 라디에이터 서포트 교환은 보상 제외
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.name}>골격 교환·판금</td>
                      <td className={styles.value}>견적금액의 최대 3~6% / 부위</td>
                    </tr>
                  </tbody>
                </table>
              </Typography>
              <Typography variant="body_2" css={notiStyle}>
                ※ 차종·부위별 차이가 있을 수 있습니다.
              </Typography>
              <Typography variant="body_2" css={section2TitleStyle}>
                [ 기타감가 보상기준 ]
              </Typography>
              <div className={styles.box}>
                <Typography variant="caption_1">
                  <ul>
                    <li>· 차량 주요부품에 대해 진단오류가 있다면, 클레임 가능합니다.</li>
                    <li>· 성능기록부로 확인되지 않거나, 중대한 진단오류가 아닌 경우 클레임 인정이 어렵습니다.</li>
                  </ul>
                </Typography>
              </div>
            </section>
            <section>
              <Typography variant="body_2" css={subTitleStyle}>
                3. 보상처리는 본사에서 직접 도와드립니다.
              </Typography>
              <div className={styles.box}>
                <Typography variant="caption_1">
                  <ul>
                    <li>· 헤이딜러 매니저가 보상금액 조율 위해 직접 유선연락 드립니다.</li>
                    <li>· 원활한 처리를 위해, 사진·견적서가 있다면 미리 준비해 주세요.</li>
                  </ul>
                </Typography>
              </div>
            </section>
            <section>
              <Typography variant="body_2" css={subTitleStyle}>
                4. 제조사 보증, 리콜 항목은 보상이 어렵습니다.
              </Typography>
              <div className={styles.box}>
                <Typography variant="caption_1">
                  <ul>
                    <li>
                      · 아쉽지만 서비스 센터 수리 과정에 드는 노력 등에 대해서도 별도 보상이 이뤄지지 않습니다. 양해
                      부탁드립니다.
                    </li>
                  </ul>
                </Typography>
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default 진단오류보상기준버튼;
